<template>
  <div>
    <el-button icon="el-icon-setting" circle size="mini"  @click="showConfig()"></el-button>
    <el-dialog
      destroy-on-close
      title="本地配置"
      :visible.sync="dialogVisible"
      width="375px"
      append-to-body>
      <span>仅当前浏览器，重启生效</span>
      <div style="width: 10px;"></div>
      <div class="tip"></div>
      <el-form :model="form" label-width="150px" size="mini" >
        <el-form-item label="页面导航保持状态：">
          <!-- <el-switch v-model="form.keepAlive"></el-switch> -->
          <el-select v-model="form.keepAlive" placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-alert
          title="打开功能后，页面就会保持状态，直到你把页面关闭重新打开或刷新页面。"
          :closable="false"
          type="success">
        </el-alert>
        <div class="tip"></div>
        <el-form-item label="首页视频流优先：">
          <!-- <el-switch v-model="form.video"></el-switch> -->
          <el-select v-model="form.video" placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-alert
          title="打开功能后，首页tab将会以视频展示优先。"
          :closable="false"
          type="success">
        </el-alert>
        <div class="tip"></div>
        <el-form-item label="server：">
          <el-input v-model="form.webRTCServer" clearable></el-input>
        </el-form-item>
        <el-alert
          title="server（RTSP2WEBRTCServer）请勿随意修改。"
          :closable="false"
          type="error">
        </el-alert>

        <div class="tip"></div>
        <el-form-item label="系统版本">
          <el-input value="V1" clearable disabled></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveConfig()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDefaultLocalSetting } from '../utils/utils'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {}
    }
  },
  methods: {
    saveConfig () {
      Object.keys(this.form).forEach(key => {
        window.localStorage.setItem(`local_setting_${key}`, this.form[key])
      })
      this.dialogVisible = false
    },
    showConfig () {
      this.dialogVisible = true
      this.form = getDefaultLocalSetting()
    }
  }

}
</script>

<style scoped>
  .tip {
    margin-bottom: 10px;
  }
</style>
