<template>
  <div class="container-sys">
    <el-card>
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="是否开启ETC">
          <el-select
            placeholder="请选择"
            v-model="sysconfig.is_etc"
            style="width: 100%"
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="关闭" value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="车辆限制进入">
          <el-select
            placeholder="请选择"
            v-model="sysconfig.cars_limit"
            style="width: 100%"
          >
            <el-option label="不限制" value="0"></el-option>
            <el-option label="限制" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="白名单特权">
          <el-select
            placeholder="请选择"
            v-model="sysconfig.cars_limit_white"
            style="width: 100%"
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="关闭" value="0"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="订单保存天数">
          <el-select
            placeholder="请选择"
            v-model="sysconfig.park_data_exp_days"
            style="width: 100%"
          >
            <el-option label="永久保存" value="-1"></el-option>
            <el-option label="30天" value="30"></el-option>
            <el-option label="60天" value="60"></el-option>
            <el-option label="90天" value="90"></el-option>
            <el-option label="120天" value="120"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否开启桌面程序">
          <el-select
            placeholder="请选择"
            v-model="sysconfig.is_desk"
            style="width: 100%"
          >
            <el-option label="开启" value="1"></el-option>
            <el-option label="关闭" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item size="medium">
          <el-button type="primary" @click="updateParkConfig" :loading="loading">保存配置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'sysconfig',
  data () {
    return {
      loading: true,
      baseUrl: '',
      sysconfig: {

      },
      form: {
        keepOrderDays: '-1',
        islimitIn: 0
      }
    }
  },
  async created () {
    this.baseUrl = this.$store.state.baseUrl // 请求base地址
    try {
      await this.getSysConfig()
    } catch (e) {
    }
    this.loading = false
  },
  methods: {
    // 获取停车场配置信息
    async getSysConfig () {
      var { data: res } = await this.$http.get(this.baseUrl + 'getSysConfig', {
        params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      // console.log(res)
      if (res.code === '0') {
        this.sysconfig = res.data
        this.sysconfig.parkCode = window.localStorage.getItem('LOGIN_PACKCODE')
        // console.log(this.sysconfig)
      } else {
        this.$message.error(res.message)
      }
    },
    // 更新停车场配置信息
    async updateParkConfig () {
      var { data: res } = await this.$http.get(this.baseUrl + 'updateParkConfig', {
        params: this.sysconfig
      })
      res = JSON.parse(res)
      // console.log(res)
      if (res.code === '0') {
        this.$message.success(res.message)
        this.getSysConfig()
      } else {
        return this.$message.error(res.message)
      }
    }

    // 智慧停车服务验证
    // async parkVerify () {
    //   const url = this.baseUrl.replace('api/client', 'park')
    //   var { data: res } = await this.$http.get(url + 'parkVerify', {
    //     params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
    //   })
    //   res = JSON.parse(res)
    //   // console.log(res)
    //   if (res.result === 'success') {
    //     this.$message.success(res.msg)
    //   } else {
    //     return this.$message.error(res.msg)
    //   }
    // },

    // 停车场域绑定
    // async bindPark () {
    //   const url = this.baseUrl.replace('api/client', 'park')
    //   var { data: res } = await this.$http.get(url + 'bindPark', {
    //     params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
    //   })
    //   res = JSON.parse(res)
    //   // console.log(res)
    //   if (res.result === 'success') {
    //     this.$message.success(res.msg)
    //   } else {
    //     return this.$message.error(res.msg)
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.container-sys {
  background-color: #f7f7f7;
  position: relative;
}
</style>
