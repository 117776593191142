<template>
  <div>
    <ProTable :columns="columns" :request="request" ref="table" v-if="showTable">
      <template v-slot:actions>
        <el-button type="success" @click="exportList" size="small">导&nbsp;&nbsp;出</el-button>
      </template>
      <template v-slot:userid="data">
        <el-tag effect="dark" type="success" size="small">{{ data.scope.row.userName }}</el-tag>
      </template>
      <template v-slot:options="data">
        <div>{{ data.scope.row.userName }}</div>
      </template>
    </ProTable>
  </div>
</template>
<script>
import { exportFileV2 } from '../../utils/ExportFileDownload'
import { formatParamsDate } from '../../utils/utils'
import ProTable from '../procomponents/ProTable.vue'
export default {
  name: 'carsdel',
  components: {
    ProTable
  },
  data () {
    return {
      columns: [
        {
          label: '车牌号码',
          name: 'carno',
          type: 'input',
          dataFormater: (value) => {
            return {
              plateNo: value
            }
          }
        },
        {
          hideInSearch: true,
          label: '驶入时间',
          name: 'inTime',
          type: 'dateRange'
        },
        {
          label: '删除时间',
          name: 'logTime',
          type: 'dateRange',
          defaultValue: [
            formatParamsDate(new Date(), -1),
            formatParamsDate(new Date(), 0, '23:59:59')
          ],
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1]
            }
          }
        },
        {
          label: '区域',
          type: 'select',
          name: 'regionCode',
          dataSource: []
        },
        {
          label: '处理人',
          name: 'userid',
          type: 'select',
          slot: 'userid',
          dataSource: []
        }
      ],
      showTable: false,
      baseUrl: ''
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl// 请求base地址
    // 进入页面查询出入记录
    this.queryUserList()
    this.getRegionDropData()
  },
  methods: {
    async request (query) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryDelOrderList', {
        params: query
      })
      res = JSON.parse(res)
      return res
    },
    // 区域列表下拉
    getRegionDropData () {
      const tmp = [{ regionCode: '', regionName: '全部' }]
      const regionList = tmp.concat(this.$store.state.parkConfig.regionList)
      const list = []
      for (let i = 0; i < regionList.length; i++) {
        const temp = {
          label: regionList[i].regionName,
          value: regionList[i].regionCode
        }
        list.push(temp)
      }
      this.columns[3].dataSource = list
    },
    async queryUserList () {
      try {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryUserList', {
          params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        const tmp = [{ userid: '', userName: '全部' }]
        const userList = tmp.concat(res.data)

        const list = []
        for (let i = 0; i < userList.length; i++) {
          const temp = {
            label: userList[i].userName,
            value: userList[i].userid
          }
          list.push(temp)
        }
        this.columns[4].dataSource = list
      } catch (e) {}
      this.showTable = true
    },
    // 导出场内车列表
    exportList () {
      const params = this.$refs.table.paramsFilter()
      exportFileV2(this, `${this.$store.state.baseUrl}exportCarsdelList`, params)
    }

  }
}
</script>

<style lang="less" scoped>

  .demo-image__placeholder{
    .block{
      padding:30px 0;
      text-align:center;
      border-right:1px solid #eff2f6;
      display:inline-block;
      box-sizing:border-box;
      vertical-align:top;
      width:100%;
      &:last-child{
        border-right:none
      }
    }
    .el-image{
      width:450px;
      height:300px
    }
    .demonstration{
      display:block;
      color:#8492a6;
      font-size:14px;
      margin-bottom:20px
    }

  }

</style>
