<template>
  <div class="flex-row" style="justify-content: center;">
    <img :src="img" alt="" style="width: 100%;height: auto;max-width: 800px;">
  </div>
</template>

<script>
export default {
  data () {
    return {
      img: ''
    }
  },
  mounted () {
    console.log(this.$route.query)
    this.img = require(`@/assets/images/${this.$route.query.img}`)
  }

}
</script>

<style>

</style>
