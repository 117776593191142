
// 规则1
export const data2DayNightRuleBody = (formBody) => {
  const body = {
    dayNightConfig: {
      dayInterval: {
        config: {
          beginMinute: {
            key: '10000001_1001',
            value: hourMinute2Minute(formBody.dayTime[0])
          },
          endMinute: {
            key: '10000001_1002',
            value: hourMinute2Minute(formBody.dayTime[1])
          }
        }
      }
    },
    dayChargeUnitPrice: {
      key: '10000001_1003',
      value: formBody.dayChargeUnitPrice
    },
    nightChargeUnitPrice: {
      key: '10000001_1004',
      value: formBody.nightChargeUnitPrice
    },
    chargeUnitMinute: {
      key: '10000001_1005',
      value: formBody.chargeUnitMinute
    },
    maxChargeInOneDay: {
      key: '10000001_1006',
      value: formBody.maxChargeInOneDay
    },
    blockWeight: {
      key: '10000001_1007',
      value: formBody.blockWeight
    },
    nightLimitCharge: {
      key: '10000001_1008',
      value: formBody.nightLimitCharge
    }
  }
  return body
}
export const dayNightRuleBody2Data = (ruleBody) => {
  const config = ruleBody.dayNightConfig.dayInterval.config
  return {
    dayTime: [
      minute2HourMinute(config.beginMinute.value),
      minute2HourMinute(config.endMinute.value)
    ],
    chargeUnitMinute: ruleBody.chargeUnitMinute.value,
    dayChargeUnitPrice: ruleBody.dayChargeUnitPrice.value,
    nightChargeUnitPrice: ruleBody.nightChargeUnitPrice.value,
    maxChargeInOneDay: ruleBody.maxChargeInOneDay.value,
    blockWeight: ruleBody.blockWeight.value,
    nightLimitCharge: ruleBody.nightLimitCharge.value
  }
}
// 规则2
export const data2TimeCountRuleBody = (formBody) => {
  return {
    unitPrice: {
      key: '10000002_1001',
      value: formBody.unitPrice
    },
    maxTime: {
      key: '10000002_1002',
      value: formBody.maxTime
    }
  }
}
export const timeCountRuleBody2data = (ruleBody) => {
  return {
    unitPrice: ruleBody.unitPrice.value,
    maxTime: ruleBody.maxTime.value
  }
}
// 时间段转换
export const timesList2RuleBody = (timesList, prefix, beginKey = 'beginMinute') => {
  // console.log('timesList', timesList)
  const list = []
  for (let i = 0; i < timesList.length; i++) {
    const item = timesList[i]
    const temp = {
      [`${beginKey}`]: {
        key: `${prefix}_${i + 1}_1`,
        // value: hourMinute2Minute(item.dateRange[0])
        value: item.dateRangeStart
      },
      endMinute: {
        key: `${prefix}_${i + 1}_2`,
        // value: hourMinute2Minute(item.dateRange[1])
        value: item.dateRangeEnd
      }
    }
    if (item.timeUnit) {
      temp.unitPrice = {
        key: `${prefix}_${i + 1}_3`,
        value: item.timeUnit
      }
    }
    if (item.chargeUnit) {
      temp.chargeUnitMinute = {
        key: `${prefix}_${i + 1}_4`,
        value: item.chargeUnit
      }
    }
    list.push(temp)
  }
  return list
}
export const ruleBody2TimesList = (ruleBody, beginKey = 'beginMinute') => {
  const list = []
  for (let i = 0; i < ruleBody.length; i++) {
    const item = ruleBody[i]
    const temp = {
      // dateRange: [
      //   minute2HourMinute(item[`${beginKey}`].value),
      //   minute2HourMinute(item.endMinute.value)
      // ]
      dateRangeStart: item[`${beginKey}`].value,
      dateRangeEnd: item.endMinute.value
    }
    if (item.unitPrice) {
      temp.timeUnit = item.unitPrice.value
    }
    if (item.chargeUnitMinute) {
      temp.chargeUnit = item.chargeUnitMinute.value
    }
    list.push(temp)
  }
  return list
}
// 规则3
export const data2TimeLengthRuleBody = (formBody) => {
  const timesList = formBody.timesList
  return {
    maxChargeInOneDay: {
      key: '10000003_1001',
      value: formBody.maxLimit
    },
    durationList: timesList2RuleBody(timesList, '10000003', 'startMinute')
  }
}
export const ruleBody2TimeLengthData = (ruleBody) => {
  return {
    maxLimit: ruleBody.maxChargeInOneDay.value,
    timesList: ruleBody2TimesList(ruleBody.durationList, 'startMinute')
  }
}
// 规则4
export const data2FirstFeeRuleBody = (formBody) => {
  return {
    freeDuration: {
      key: '20000001_1001',
      value: formBody.firstFree
    },
    loopEveryday: {
      key: '20000001_1004',
      value: formBody.loopEveryday
    },
    sumIn24Hour: {
      key: '20000001_1005',
      value: formBody.sumIn24Hour
    },
    validTimes: {
      key: '20000001_1002',
      value: formBody.firstFreeTimes
    },
    overtimeDeal: {
      key: '20000001_1003',
      value: formBody.firstFreeTimeOut
    }
  }
}
export const firstFeeRuleBody2Data = (ruleBody) => {
  // console.log('ruleBody', ruleBody)
  return {
    firstFree: ruleBody.freeDuration.value,
    loopEveryday: ruleBody.loopEveryday.value === 'true',
    sumIn24Hour: ruleBody.sumIn24Hour.value === 'true',
    firstFreeTimes: ruleBody.validTimes.value,
    firstFreeTimeOut: ruleBody.overtimeDeal.value
  }
}
// 规则5
export const data2FeeRuleBody = (formBody) => {
  return {
    freeInterval: timesList2RuleBody(formBody.timesList, '20000002')
  }
}
export const feeRuleBody2Data = (ruleBody) => {
  return {
    timesList: ruleBody2TimesList(ruleBody.freeInterval)
  }
}
/**
 *
 * @param {*} formBody 表单
 * @param {*} ceCode 停车场编码
 * @param {*} ceSubBlockCode 区域code
 * @param {*} ruleApplyObj 车型
 * @returns
 */
export const commitForm = (formBody, ceCode, ceSubBlockCode, ruleApplyObj, ceSubBlockName, ceName) => {
  const basicInfo = {
    ceCode,
    ceSubBlockCode,
    ruleApplyObj,
    ceSubBlockName,
    ceName
  }
  if (formBody.ruleType === 1) {
    // 1.日照市白天夜晚分段标准计费规则
    return {
      basicInfo,
      ruleInfo: {
        ruleId: '10000001',
        ruleBody: data2DayNightRuleBody(formBody),
        expirationDate: getExpirationDateRange(formBody)
      }
    }
  } else if (formBody.ruleType === 2) {
    return {
      basicInfo,
      ruleInfo: {
        ruleId: '10000002',
        ruleBody: data2TimeCountRuleBody(formBody),
        expirationDate: getExpirationDateRange(formBody)
      }
    }
  } else if (formBody.ruleType === 3) {
    return {
      basicInfo,
      ruleInfo: {
        ruleId: '10000003',
        ruleBody: data2TimeLengthRuleBody(formBody),
        expirationDate: getExpirationDateRange(formBody)
      }
    }
  } else if (formBody.ruleType === 4) {
    return {
      basicInfo,
      ruleInfo: {
        ruleId: '20000001',
        ruleBody: data2FirstFeeRuleBody(formBody),
        expirationDate: getExpirationDateRange(formBody)
      }
    }
  } else if (formBody.ruleType === 5) {
    return {
      basicInfo,
      ruleInfo: {
        ruleId: '20000002',
        ruleBody: data2FeeRuleBody(formBody),
        expirationDate: getExpirationDateRange(formBody)
      }
    }
  }
}

export const getExpirationDateRange = (formBody) => {
  // if (formBody.expirationDateEnd) {
  //   return [formBody.expirationDateStart, formBody.expirationDateEnd]
  // }
  // return [formBody.expirationDateStart]
  return formBody.expirationDate
}

/**
 * 服务器数据转 form
 * @param {*} result 默认的form数据
 * @param {*} data 服务器data
 * @returns 返回form 输入据
 */
export const data2Form = (result, data) => {
  const ruleId = data.ruleInfo.ruleId
  const ruleBody = data.ruleInfo.ruleBody
  if (ruleId === '10000001') {
    result = { ...result, ...dayNightRuleBody2Data(ruleBody), ruleType: 1 }
  } else if (ruleId === '10000002') {
    result = { ...result, ...timeCountRuleBody2data(ruleBody), ruleType: 2 }
  } else if (ruleId === '10000003') {
    result = { ...result, ...ruleBody2TimeLengthData(ruleBody), ruleType: 3 }
  } else if (ruleId === '20000001') {
    result = { ...result, ...firstFeeRuleBody2Data(ruleBody), ruleType: 4 }
  } else if (ruleId === '20000002') {
    result = { ...result, ...feeRuleBody2Data(ruleBody), ruleType: 5 }
  }
  // console.log(result)
  // 过期时间
  // result.expirationDateStart = data.ruleInfo.expirationDate[0]
  // if (data.ruleInfo.expirationDate.length >= 1) {
  //   result.expirationDateEnd = data.ruleInfo.expirationDate[1]
  // }
  result.expirationDate = data.ruleInfo.expirationDate
  // 作用车型
  result.area = [data.basicInfo.ceSubBlockCode, data.basicInfo.ruleApplyObj]
  return result
}
export const ruleId2Name = (ruleId) => {
  if (ruleId === '10000001') {
    return '日照市白天夜晚分段标准'
  } else if (ruleId === '10000002') {
    return '按次收费'
  } else if (ruleId === '10000003') {
    return '按停车时长收费'
  } else if (ruleId === '20000001') {
    return '首停免费'
  } else if (ruleId === '20000002') {
    return '免费时段规则（1个自然天内）'
  }
  return '-'
}

export const formatTime = (num) => (num < 10 ? `0${num}` : num.toString())
/**
 * 将小时分钟转化为分钟
 * 忽略秒
 * @param date 00:00:00 （00：00）
 */
export const hourMinute2Minute = (date) => {
  const d = date.split(':')
  const hour = parseInt(d[0])
  const minute = parseInt(d[1])
  return hour * 60 + minute
}

/**
 * 将分钟转化为小时分钟
 * @param totalMinutes 60
 * @returns 01:00
 */
export const minute2HourMinute = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return `${formatTime(hours)}:${formatTime(minutes)}`
}
export const areaCarType = () => {
  return [
    {
      value: '100',
      label: '小型车'
    },
    {
      value: '101',
      label: '中型车'
    },
    {
      value: '102',
      label: '大型车'
    },
    {
      value: '103',
      label: '新能源车'
    },
    {
      value: '104',
      label: '大型新能源车'
    },
    {
      value: '110',
      label: '特殊车辆（黑牌）'
    },
    {
      value: '111',
      label: '军警免费车型'
    },
    {
      value: '190',
      label: '未知'
    },
    {
      value: '191',
      label: '其他类型'
    },
    {
      value: '999',
      label: '全部对象'
    }
  ]
}
export const getDefaultFormData = () => {
  return {
    // 有效期
    expirationDate: null,
    // expirationDateStart: null,
    // expirationDateEnd: null,
    ruleType: 1,
    // 区域-车 数组
    area: [],
    // 规则1
    // 白天开始结束时间
    dayTime: '',
    // 计费时间单位
    chargeUnitMinute: '',
    // 白天计费单价
    dayChargeUnitPrice: '',
    // 夜晚计费单价
    nightChargeUnitPrice: '',
    // 一天最大限额
    maxChargeInOneDay: '',
    // 白天夜晚跨时段计费比重
    blockWeight: '',
    // 夜晚最大限额
    nightLimitCharge: '',
    // 规则2
    // 单价值
    unitPrice: '',
    // 最大时长
    maxTime: '',
    // 规则3
    // 一天最大限额
    maxLimit: '',
    // 时间段 按停车时长+免费时间段
    timesList: [],
    // 规则4
    // 免费时长
    firstFree: null,
    // 1. 每24小时生效
    loopEveryday: false,
    // 2. 24小时内时长累积
    sumIn24Hour: false,
    // 可使用次数（1自然日内）
    firstFreeTimes: null,
    // 超过免费时长处理方式
    firstFreeTimeOut: null
  }
}
