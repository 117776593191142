<template>
  <div>
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
          <el-button type="success" @click="exportList" size="small">导&nbsp;&nbsp;出</el-button>
      </template>
      <template v-slot:options="data">
        <el-button type="primary" icon="el-icon-picture" size="mini" @click="showImgDialogVisible(data.scope.row.pic)">查看图片</el-button>
      </template>
    </ProTable>
    <el-dialog title="识别图片" :visible.sync="imgDialogVisible" width="80%" @close="imgDialogClose">
      <div class="demo-image__placeholder">
        <div class="block">
            <el-image style="width: 100%;max-width: 500px;" :src="images.pic" fit="cover" :preview-src-list="[images.pic]"></el-image>
          </div>
        </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogClose">关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { exportFileV2 } from '../../utils/ExportFileDownload'
import ProTable from '../procomponents/ProTable.vue'
import { formatParamsDate } from '../../utils/utils'
export default {
  name: 'detect',
  components: {
    ProTable
  },
  data () {
    return {
      columns: [
        {
          label: '过车时间',
          name: 'driveTime',
          type: 'dateRange',
          width: 140,
          defaultValue: [
            formatParamsDate(new Date(), -1),
            formatParamsDate(new Date(), 0, '23:59:59')
          ],
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1]
            }
          }
        },
        {
          label: '车牌号码',
          name: 'carno',
          type: 'input',
          dataFormater: (value) => {
            return {
              plateNo: value
            }
          }
        },
        {
          label: '过车方向',
          name: 'direction',
          type: 'select',
          dataSource: [
            {
              label: '入场',
              value: '9'
            },
            {
              label: '出场',
              value: '10'
            }
          ]
        },
        {
          hideInSearch: true,
          label: '出入口',
          name: 'pointname',
          type: 'input'
        },
        {
          hideInSearch: true,
          label: '区域',
          name: 'regionName',
          type: 'input'
        },
        {
          hideInSearch: true,
          label: '操作',
          name: 'options',
          width: 120,
          slot: 'options',
          fixed: 'right'
        }
      ],
      imgDialogVisible: false, // 控制图片窗口显示
      baseUrl: '',
      images: {
        pic: ''
      }
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl// 请求base地址
  },
  methods: {
    async request (query) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryDetectList', {
        params: query
      })
      res = JSON.parse(res)
      return res
    },
    // 打开订单图片窗口
    showImgDialogVisible (pic) {
      // pic = dealImageUrl(pic)
      // 设置入场出场图片
      this.images.pic = pic
      this.imgDialogVisible = true
    },

    // 关闭订单图片窗口
    imgDialogClose () {
      this.images.pic = ''
      this.imgDialogVisible = false
    },

    // 导出
    exportList () {
      const params = this.$refs.table.paramsFilter()
      exportFileV2(this, `${this.$store.state.baseUrl}exportDetectList`, params)
    }

  }
}
</script>

<style lang="less" scoped>

  .demo-image__placeholder{
    .block{
      padding:30px 0;
      text-align:center;
      border-right:1px solid #eff2f6;
      display:inline-block;
      box-sizing:border-box;
      vertical-align:top;
      width:100%;
      border-right:none
    }
    .el-image{
      width:450px;
      height:300px
    }
    .demonstration{
      display:block;
      color:#8492a6;
      font-size:14px;
      margin-bottom:20px
    }

  }

</style>
