<template>
  <div class="container-sss">
    <ProTable :columns="columns" :request="request" ref="table" >
      <template v-slot:actions>
        <el-button type="success" @click="exportList" size="small">导&nbsp;&nbsp;出</el-button>
      </template>
      <template v-slot:userid="data">
        <el-tag effect="dark" type="success">{{ data.scope.row.userName }}</el-tag>
      </template>
      <template v-slot:options="data">
        <el-button type="primary" icon="el-icon-picture" size="mini" @click="showImgDialogVisible(data.scope.row.imgPath)">查看图片</el-button>
      </template>
    </ProTable>

     <el-dialog title="图片" :visible.sync="imgDialogVisible" width="40%" @close="imgDialogClose">
      <div class="demo-image__placeholder">
        <div class="block">
            <el-image :src=images.pic ></el-image>
          </div>
        </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogClose">关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { formatParamsDate } from '@/utils/utils'
import { exportFileV2 } from '../../utils/ExportFileDownload'
import ProTable from '../procomponents/ProTable.vue'
export default {
  name: 'carno',
  components: {
    ProTable
  },
  data () {
    return {
      columns: [
        {
          label: '车牌号码',
          name: 'carno',
          type: 'input',
          dataFormater: (value) => {
            return {
              plateNo: value
            }
          }
        },
        {
          hideInSearch: true,
          label: '设备名称',
          name: 'deviceName',
          type: 'dateRange'
        },
        {
          label: '操作时间',
          name: 'optTime',
          type: 'dateRange',
          defaultValue: [
            formatParamsDate(new Date(), -1),
            formatParamsDate(new Date(), 0, '23:59:59')
          ],
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1]
            }
          }
        },
        {
          label: '操作人',
          name: 'userid',
          type: 'select',
          slot: 'userid',
          dataSource: []
        }
      ],
      imgDialogVisible: false, // 控制图片窗口显示
      start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0),
      end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59),
      baseUrl: '',
      userList: [],
      images: {
        pic: ''
      }
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl// 请求base地址
    this.queryUserList()
  },
  methods: {
    async request (query) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryCarnoList', {
        params: query
      })
      res = JSON.parse(res)
      return res
    },
    async queryUserList () {
      try {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryUserList', {
          params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        const tmp = [{ userid: '', userName: '全部' }]
        const userList = tmp.concat(res.data)

        const list = []
        for (let i = 0; i < userList.length; i++) {
          const temp = {
            label: userList[i].userName,
            value: userList[i].userid
          }
          list.push(temp)
        }
        this.columns[3].dataSource = list
      } catch (e) {}
    },
    // 打开订单图片窗口
    showImgDialogVisible (pic) {
      // 设置入场出场图片
      this.images.pic = 'http://' + pic
      this.imgDialogVisible = true
    },

    // 关闭订单图片窗口
    imgDialogClose () {
      this.images.pic = ''
      this.imgDialogVisible = false
    },

    // 导出列表
    exportList () {
      const params = this.$refs.table.paramsFilter()
      exportFileV2(this, `${this.$store.state.baseUrl}exportCarnoList`, params)
    }

  }
}
</script>

<style lang="less" scoped>
  .container-sss{
    background-color:#f7f7f7;
    position: relative;
  }
  .el-col {
    margin: 7px 7px 7px 7px;
  }

  .demo-image__placeholder{
    .block{
      padding:30px 0;
      text-align:center;
      border-right:1px solid #eff2f6;
      display:inline-block;
      box-sizing:border-box;
      vertical-align:top;
      width:100%;
      &:last-child{
        border-right:none
      }
    }
    .el-image{
      width:450px;
      height:300px
    }
    .demonstration{
      display:block;
      color:#8492a6;
      font-size:14px;
      margin-bottom:20px
    }

  }

</style>
