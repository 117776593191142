<template>
  <div class="flex-row" style="flex-wrap: wrap">
    <div class="item">
      <!-- <RTSPVideoPlayer :rtspURL="inCameraRTSP"/>-->
      <!-- <WebSocketPlayer url="ws://192.168.1.120:9080/ws"/> -->
      <VideoContainer
        :equip="equipItem(inEquipDeviceCode)"
        :picData="inEquipDevicePicData"
      />
      <el-card shadow="always" class="card">
        <div slot="header">
          <el-select
            size="mini"
            v-model="inEquipDeviceCode"
            placeholder="请选择入口设备"
          >
            <el-option
              v-for="item in inOutList('9')"
              :key="item.devicecode"
              :label="item.devicename"
              :value="item.devicecode"
            >
            </el-option>
          </el-select>
          <el-button
            class="button-top"
            type="primary"
            size="mini"
            @click="openGate('9')"
            >入口抬杆</el-button
          >
          <!-- <el-button style="float: right; padding: 10px 0; margin-right: 10px;" type="primary">刷新相机</el-button> -->
        </div>
        <el-form ref="form" label-width="100px" size="mini">
          <el-form-item label="车牌号码：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              effect="dark"
              >{{ inOrder.plateNo }}</el-tag
            >
          </el-form-item>
          <el-form-item label="入场时间：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              effect="dark"
              >{{ inOrder.timeIn }}</el-tag
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="item">
      <VideoContainer
        :equip="equipItem(outEquipDeviceCode)"
        :picData="outEquipDevicePicData"
      />
      <el-card shadow="always" class="card">
        <div slot="header">
          <el-select
            size="mini"
            v-model="outEquipDeviceCode"
            placeholder="请选择出口设备"
          >
            <el-option
              v-for="item in inOutList('10')"
              :key="item.devicecode"
              :label="item.devicename"
              :value="item.devicecode"
            >
            </el-option>
          </el-select>

          <el-button
            class="button-top"
            type="primary"
            size="mini"
            @click="openGate('10')"
            >出口抬杆</el-button
          >
          <!-- <el-button style="float: right; padding: 10px 0; margin-right: 10px;" type="primary">刷新相机</el-button> -->
        </div>
        <el-form ref="form" label-width="100px" size="mini">
          <el-form-item label="车牌信息：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              style="margin-right: 20px"
              effect="dark"
              >{{ outOrder.plateNo }}</el-tag
            >
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              effect="dark"
              >{{ outOrder.plateType }}</el-tag
            >
          </el-form-item>
          <el-form-item label="出入时间：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              style="margin-right: 20px"
              effect="dark"
              >{{ outOrder.timeIn }}</el-tag
            >
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              style="margin-right: 20px"
              effect="dark"
              >{{ outOrder.timeOut }}</el-tag
            >
          </el-form-item>
          <el-form-item label="停车时长：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              effect="dark"
              >{{ outOrder.duration }}</el-tag
            >
          </el-form-item>
          <el-form-item label="停车费用：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              style="margin-right: 20px"
              effect="dark"
              >{{ outOrder.payFee }}</el-tag
            >
          </el-form-item>
          <el-form-item label="支付方式：">
            <el-tag
              size="mini"
              type="danger"
              class="label-content"
              effect="dark"
              style="margin-right: 30px"
              >{{ outOrder.payType }}</el-tag
            >
            <span v-if="showTodayButton">
              <el-checkbox
                v-model="checked1"
                :disabled="checked1Disable"
                label="免除第一天"
                border
                size="medium"
                @change="orderDiscount('first')"
              ></el-checkbox>
              <el-checkbox
                v-model="checked2"
                :disabled="checked2Disable"
                label="免除最后一天"
                border
                size="medium"
                @change="orderDiscount('last')"
              ></el-checkbox>
            </span>
          </el-form-item>
          <div
            size="medium"
            class="flex-row"
            style="flex-wrap: wrap; align-items: center"
          >
            <el-button
              type="danger"
              class="btn2"
              size="mini"
              :disabled="outOrder.btnClick"
              @click="endOrder('1')"
              >现金放行</el-button
            >
            <el-button
              size="mini"
              type="danger"
              class="btn2"
              :disabled="outOrder.btnClick"
              @click="endOrder('0')"
              >免费放行</el-button
            >
            <el-button
              size="mini"
              type="danger"
              class="btn2"
              @click="manaulRecognize()"
              >人工识别</el-button
            >
            <!--             <el-popconfirm
              @confirm="cancelTodayPayment()"
              v-if="showTodayButton"
              :title="'确定免除' + outOrder.plateNo + '今日费用？'"
            >
              <el-button slot="reference" size="mini" type="primary"  :disabled="outOrder.btnClick"  v-loading="cancelTodayLoading">免除今日费用</el-button>
            </el-popconfirm> -->
          </div>
        </el-form>
      </el-card>
    </div>

    <el-dialog
      title="人工识别"
      :visible.sync="rgsbDialogVisible"
      width="80%"
      @close="rgsbDialogClose"
    >
      <div class="demo-image__placeholder" v-loading="loading">
        <!-- <div class="block">
          <el-image :src=images.pic></el-image>
        </div> -->
        <el-form :model="rgForm" ref="rgFormRef" label-width="90px">
          <el-form-item label="车牌号码：">
            <el-input v-model="rgForm.plateNo" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="车牌颜色：">
            <el-select placeholder="请选择" v-model="rgForm.licenceType">
              <el-option
                v-for="(item, index) in licensePlateColors"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="plate-select-div pc-input">
          <div>
            <el-tag
              v-for="item in provicesItems"
              :key="item.label"
              :type="item.type"
              effect="dark"
              class="plate-select"
              @click="labelClick(item.label)"
            >
              {{ item.label }}
            </el-tag>
          </div>
          <div>
            <el-tag
              v-for="item in letterItem"
              :key="item.label"
              :type="item.type"
              effect="dark"
              class="plate-select"
              @click="labelClick(item.label)"
            >
              {{ item.label }}
            </el-tag>
          </div>
          <div>
            <el-tag
              v-for="item in numberItem"
              :key="item.label"
              :type="item.type"
              effect="dark"
              class="plate-select"
              @click="labelClick(item.label)"
            >
              {{ item.label }}
            </el-tag>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" class="m10" @click="rgsbDialogClose"
          >取 消</el-button
        >
        <el-button type="warning" class="m10" @click="deletePlateLetter('1')"
          >退 格</el-button
        >
        <el-button type="danger" class="m10" @click="deletePlateLetter('2')"
          >清 空</el-button
        >
        <el-button class="m10" type="primary" @click="rgsbUp">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getNumberOfDays, debounce, licensePlateColors } from "@/utils/utils";
import VideoContainer from "./VideoContainer/index.vue";
export default {
  components: {
    VideoContainer,
  },
  data() {
    return {
      failCount: 0,
      licensePlateColors: licensePlateColors,
      showTodayButton: false,
      cancelTodayLoading: false,
      // devicecode
      inEquipDeviceCode: window.localStorage.getItem("inEquipDeviceCode"),
      // devicecode
      outEquipDeviceCode: window.localStorage.getItem("outEquipDeviceCode"),
      inEquipDevicePicData: null,
      outEquipDevicePicData: null,
      provicesItems: [
        { type: "", label: "鲁" },
        { type: "", label: "京" },
        { type: "", label: "津" },
        { type: "", label: "沪" },
        { type: "", label: "渝" },
        { type: "", label: "冀" },
        { type: "", label: "豫" },
        { type: "", label: "云" },
        { type: "", label: "辽" },
        { type: "", label: "黑" },
        { type: "", label: "湘" },
        { type: "", label: "皖" },
        { type: "", label: "新" },
        { type: "", label: "苏" },
        { type: "", label: "浙" },
        { type: "", label: "赣" },
        { type: "", label: "鄂" },
        { type: "", label: "桂" },
        { type: "", label: "甘" },
        { type: "", label: "晋" },
        { type: "", label: "蒙" },
        { type: "", label: "陕" },
        { type: "", label: "吉" },
        { type: "", label: "闽" },
        { type: "", label: "贵" },
        { type: "", label: "粤" },
        { type: "", label: "青" },
        { type: "", label: "藏" },
        { type: "", label: "川" },
        { type: "", label: "宁" },
        { type: "", label: "琼" },
        { type: "", label: "警" },
      ],
      letterItem: [
        { type: "", label: "L" },
        { type: "", label: "A" },
        { type: "", label: "B" },
        { type: "", label: "C" },
        { type: "", label: "D" },
        { type: "", label: "E" },
        { type: "", label: "F" },
        { type: "", label: "G" },
        { type: "", label: "H" },
        { type: "", label: "I" },
        { type: "", label: "J" },
        { type: "", label: "K" },
        { type: "", label: "M" },
        { type: "", label: "N" },
        { type: "", label: "O" },
        { type: "", label: "P" },
        { type: "", label: "Q" },
        { type: "", label: "R" },
        { type: "", label: "S" },
        { type: "", label: "T" },
        { type: "", label: "U" },
        { type: "", label: "V" },
        { type: "", label: "W" },
        { type: "", label: "X" },
        { type: "", label: "Y" },
        { type: "", label: "Z" },
      ],
      numberItem: [
        { type: "", label: "0" },
        { type: "", label: "1" },
        { type: "", label: "2" },
        { type: "", label: "3" },
        { type: "", label: "4" },
        { type: "", label: "5" },
        { type: "", label: "6" },
        { type: "", label: "7" },
        { type: "", label: "8" },
        { type: "", label: "9" },
      ],
      myTimer: null, // 后台websocket连接心跳定时器
      isconnect: false, // 控制重连开关
      baseUrl: "",
      wsUrl: "",
      loading: false,
      rgsbDialogVisible: false,
      checked1: false,
      checked2: false,
      checked1Disable: true,
      checked2Disable: true,
      parkinggDays: 0,
      rgForm: {
        plateNo: "",
        licenceType: "1",
      },
      // 停车场websocket
      wsPark: null,
      // 入场订单
      inOrder: {
        orderNo: "",
        plateNo: "暂无数据",
        timeIn: "暂无数据",
      },
      // 离场订单
      outOrder: {
        btnClick: true,
        orderNo: "",
        plateNo: "暂无数据",
        timeIn: "暂无数据",
        timeOut: "暂无数据",
        payFee: "暂无数据",
        payFeeN: "",
        payType: "暂无数据",
        duration: "暂无数据",
        plateType: "暂无数据",
      },
      equipList: [],
    };
  },
  watch: {
    inEquipDeviceCode: (newValue, oldValue) => {
      window.localStorage.setItem("inEquipDeviceCode", newValue);
    },
    outEquipDeviceCode: (newValue, oldValue) => {
      window.localStorage.setItem("outEquipDeviceCode", newValue);
    },
  },
  computed: {
    inOutList() {
      return function (videofunc) {
        const resultList = [];
        this.equipList.map((item) => {
          if (item.videofunc === videofunc) {
            resultList.push(item);
          }
        });
        return resultList;
      };
    },
    equipItem() {
      return function (deviceCode) {
        const list = this.equipList;
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (item.devicecode === deviceCode) {
            return item;
          }
        }
        return {};
      };
    },
  },
  // 进入页面执行逻辑
  mounted() {
    this.baseUrl = this.$store.state.baseUrl; // 请求base地址
    this.wsUrl = this.$store.state.wsUrl; // 后台websocket地址
    this.showTodayButton = this.$store.state.parkConfig.showTodayButton;
    this.initDefaultDevice();
    // 初始化停车场websocket
    this.createWebSocket();
    // 获取当前正要离场的订单，有可能获取不到
    this.getCurrentOrder();
  },
  // 页面离开关闭websocket
  beforeDestroy() {
    if (this.wsPark !== null) {
      this.wsPark.close();
    }
    this.destroyTimer();
    this.endHeartBreat();
  },
  methods: {
    async cancelTodayPayment() {
      if (this.outOrder.orderNo.length <= 0) {
        this.$message.error("出口订单号为空");
        return;
      }
      this.cancelTodayLoading = true;
      // const data = {
      //   userId: window.localStorage.getItem('LOGIN_USERID'),
      //   parkCode: window.localStorage.getItem('LOGIN_PACKCODE'),
      //   orderNo: this.outOrder.orderNo
      // }
      try {
        const params = `?userId=${window.localStorage.getItem(
          "LOGIN_USERID"
        )}&parkCode=${window.localStorage.getItem("LOGIN_PACKCODE")}&orderNo=${
          this.outOrder.orderNo
        }`;
        var { data: res } = await this.$http.post(
          this.baseUrl + "cancel-today-payment" + params
        );
        // console.log('cancelTodayPayment', res)
        res = JSON.parse(res);
        if (res.code === "0") {
          this.$message.success(res.message);
          this.outOrder.btnClick = true;
        } else {
          this.$message.error(res.message);
        }
      } catch (e) {
        console.error(e);
        this.$message.error("请求失败");
      } finally {
        this.cancelTodayLoading = false;
      }
    },
    /**
     * 将本地保存的相机加载到列表
     */
    initDefaultDevice() {
      const list = this.$store.state.parkConfig.equipList;
      // console.log('initDefaultDevice', list)
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.videofunc === "9" && !this.inEquipDeviceCode) {
          // 入口
          this.inEquipDeviceCode = item.devicecode;
        }
        if (item.videofunc === "10" && !this.outEquipDeviceCode) {
          // 出口
          this.outEquipDeviceCode = item.devicecode;
        }

        if (this.outEquipDeviceCode && this.inEquipDeviceCode) {
          break;
        }
      }
      this.equipList = list;
    },

    // 现金抬杆或者免费放行
    // 添加防抖函数10s, 防止多次点击 mod by sq 20210630
    endOrder: debounce(async function (moneyFlag) {
      let money = 0;
      let isFree = true;
      if (moneyFlag === "1") {
        money = this.outOrder.payFeeN;
        isFree = false;
      }
      const userId = window.localStorage.getItem("LOGIN_USERID");
      var { data: res } = await this.$http.get(this.baseUrl + "opengatebyfee", {
        params: {
          fee: money,
          orderNo: this.outOrder.orderNo,
          isFree: isFree,
          userId: userId,
          parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
        },
      });
      res = JSON.parse(res);
      if (res.code === "0") {
        this.$message.success(res.message);
        this.outOrder.btnClick = true;
        if (this.showTodayButton) {
          this.checked1Disable = true;
          this.checked2Disable = true;
        }
        if (moneyFlag == "1") {
          this.outOrder.payType = "现金";
        }
        if (moneyFlag == "0") {
          this.outOrder.payType = "免费";
        }
      } else {
        return this.$message.error(res.message);
      }
    }, 10000),
    // 打开websockt之后创建监听，接收消息推送
    initParkWs() {
      const that = this;
      that.wsPark.onopen = function (event) {
        console.log("socket init ok");
        that.startHeartBreat();
      };
      that.wsPark.onmessage = function (event) {
        // console.log('onmessage', event.data)
        const res = JSON.parse(event.data);
        const messageType = res.messageType;
        if (messageType === "IN_MESSAGE") {
          // 驶入订单推送接收
          if (that.inEquipDeviceCode === res.deviceCode) {
            // 判断是否是当前打开的入口摄像头
            that.inOrder.orderNo = res.orderNo;
            that.inOrder.plateNo = res.plateNo;
            that.inOrder.timeIn = res.timeIn;
          }
        } else if (messageType === "OUT_MESSAGE") {
          // 驶离订单推送接收
          if (that.outEquipDeviceCode === res.deviceCode) {
            // 判断是否是当前打开的出口摄像头
            that.outOrder.orderNo = res.orderNo;
            that.outOrder.plateNo = res.plateNo;
            that.outOrder.timeIn = res.timeIn;
            that.outOrder.timeOut = res.timeOut;
            that.outOrder.payFee = res.payFee + " 元";
            that.outOrder.payFeeN = res.payFee;
            that.outOrder.payType = res.payType;
            that.outOrder.plateType = res.plateType;
            that.outOrder.duration = res.duration + " 分钟";
            if (res.payFee > 0) {
              that.outOrder.btnClick = false;
            }
            // 打折信息
            // console.log('=====' + that.$store.state.sysConfig.isShowSmFree)
            if (that.showTodayButton) {
              if (that.outOrder.payType != "等待支付") {
                that.checked1Disable = true;
                that.checked2Disable = true;
              } else {
                that.checked1Disable = false;
                that.checked2Disable = false;
              }
              that.checked1 = false;
              that.checked2 = false;
              /*               const days = getNumberOfDays(res.timeIn, res.timeOut);
              if (days === 1) {
                that.checked1Disable = false;
                that.checked2Disable = false;
                that.parkinggDays = 2;
              } else if (days >= 2) {
                that.checked1Disable = false;
                that.checked2Disable = false;
                that.parkinggDays = 3;
              } */
              // console.log('inDay: ' + inDay + ' outDay: ' + outDay)
            }
          }
        } else if (messageType === "PAY_MESSAGE") {
          // 支付订单推送接收
          if (that.outEquipDeviceCode === res.deviceCode) {
            // 判断是否是当前打开的出口摄像头
            that.outOrder.orderNo = res.orderNo;
            that.outOrder.plateNo = res.plateNo;
            that.outOrder.timeIn = res.timeIn;
            that.outOrder.timeOut = res.timeOut;
            that.outOrder.payFee = res.payFee + " 元";
            that.outOrder.payFeeN = res.payFee;
            that.outOrder.payType = res.payType;
            that.outOrder.plateType = res.plateType;
            that.outOrder.duration = res.duration + " 分钟";
            that.outOrder.btnClick = true;
            if (that.showTodayButton) {
              that.checked1Disable = true;
              that.checked2Disable = true;
            }
          }
        } else if (messageType === "NEW_CHARGE") {
          // 支付订单推送接收
          if (that.outEquipDeviceCode === res.deviceCode) {
            // 判断是否是当前打开的出口摄像头
            that.outOrder.orderNo = res.orderNo;
            that.outOrder.plateNo = res.plateNo;
            that.outOrder.timeIn = res.timeIn;
            that.outOrder.timeOut = res.timeOut;
            that.outOrder.payFee = res.payFee + " 元";
            that.outOrder.payFeeN = res.payFee;
            that.outOrder.payType = res.payType;
            that.outOrder.plateType = res.plateType;
            that.outOrder.duration = res.duration + " 分钟";
          }
        } else if (messageType === "PONG") {
          // console.log('PONG')
        }
        // 判断进入场有没有图片返回
        if (res.picData) {
          const temp = {
            url: res.picData.imagePath,
            time: res.picData.time,
          };
          if (that.inEquipDeviceCode === res.deviceCode) {
            that.inEquipDevicePicData = temp;
            // console.log('更新入场', temp)
          }
          if (that.outEquipDeviceCode === res.deviceCode) {
            that.outEquipDevicePicData = temp;
            // console.log('更新出场', temp)
          }
        }
      };
      that.wsPark.onclose = function (event) {
        console.log("onclose", event);
        that.endHeartBreat();
        if (event.code !== 1000) {
          // 异常关闭重新连接
          that.failCount = that.failCount + 1;
          if (that.failCount == 3) {
            console.info("网络检查");
            that.checkNetwork();
          }
          that.reconnectWs();
        }
      };
      that.wsPark.onerror = function (event) {
        console.log("socket init onerror", event);
        // that.reconnectWs()
      };
      // that.failCount = 0;
    },
    // 创建websocket
    createWebSocket() {
      const that = this;
      if (this.wsPark) {
        return;
      }
      try {
        const parkCode = window.localStorage.getItem("LOGIN_PACKCODE");
        const userId = window.localStorage.getItem("LOGIN_USERID");
        that.wsPark = new WebSocket(
          this.wsUrl + `?parkCode=${parkCode}&userId=${userId}`
        );
        that.destroyTimer();
        that.initParkWs();
      } catch (e) {
        console.log("catch", e);
        that.reconnectWs();
      }
    },
    startHeartBreat() {
      const that = this;
      this.endHeartBreat();
      // console.log('startHeartBreat')
      this.heartBreatTimer = setInterval(function () {
        // console.log('heartBreat', that.wsPark)
        if (that.wsPark) {
          try {
            that.wsPark.send("PING");
          } catch (e) {}
        }
      }, 15 * 1000);
    },
    endHeartBreat() {
      window.clearInterval(this.heartBreatTimer);
    },
    // ws重连机制
    reconnectWs() {
      var that = this;
      that.wsPark = null;
      that.destroyTimer();
      this.myTimer = setTimeout(function () {
        that.createWebSocket();
      }, 3 * 1000);
    },
    // 销毁定时器
    destroyTimer() {
      if (this.myTimer != null) {
        window.clearTimeout(this.myTimer); // 出错删除定时器
      }
    },
    // 检查本地网络，防止甩锅
    checkNetwork() {
      fetch("https://api.ipify.org/")
        .then((resp) => {
          console.info("network works");
        })
        .catch((e) => {
          const parkCode = window.localStorage.getItem("LOGIN_PACKCODE");
          if (["3711104", "371185"].includes(parkCode)) {
            alert("本地网络可能已中断，请检查。时间：" + new Date());
          }
        });
    },
    // 抬杆接口
    async openGate(direction) {
      let devicecode = "";
      if (direction === "9") {
        devicecode = this.inEquipDeviceCode;
      } else {
        devicecode = this.outEquipDeviceCode;
      }
      // console.log(devicecode)
      if (devicecode === "") return;
      const userId = window.localStorage.getItem("LOGIN_USERID");
      var { data: res } = await this.$http.get(this.baseUrl + "controlgate", {
        params: {
          deviceNo: devicecode,
          userId: userId,
          parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
        },
      });
      res = JSON.parse(res);
      if (res.code === "0") {
        this.$message.success(res.message);
      } else {
        return this.$message.error(res.message);
      }
    },

    // 手动获取离场订单信息
    async getCurrentOrder() {
      const that = this;
      var { data: res } = await this.$http.get(
        this.baseUrl + "getCurrentOrder",
        {
          params: {
            deviceNo: this.outEquipDeviceCode,
            parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
          },
        }
      );
      res = JSON.parse(res);
      if (res.code === "0") {
        that.outOrder.orderNo = res.orderNo;
        that.outOrder.plateNo = res.plateNo;
        that.outOrder.timeIn = res.timeIn;
        that.outOrder.timeOut = res.timeOut;
        that.outOrder.payFee = res.payFee + " 元";
        that.outOrder.payFeeN = res.payFee;
        that.outOrder.payType = res.payType;
        that.outOrder.plateType = res.plateType;
        that.outOrder.duration = res.duration + " 分钟";
        if (res.payFee > 0) {
          that.outOrder.btnClick = false;
        }
        that.checked1Disable = true;
        that.checked2Disable = true;
        that.checked1 = false;
        that.checked2 = false;
        const days = getNumberOfDays(res.timeIn, res.timeOut);
        if (days === 1) {
          that.checked1Disable = false;
          that.checked2Disable = false;
          that.parkinggDays = 2;
        } else if (days >= 2) {
          that.checked1Disable = false;
          that.checked2Disable = false;
          that.parkinggDays = 3;
        }
      }
    },
    // 人工识别方法
    // 解决商场、其他地库出入口比较陡峭的通道，在车流量较大的时候，出口车辆未识别
    // 又无法倒车的情况下，需要岗亭工作人员，手动识别车辆信息，放行
    manaulRecognize() {
      // this.images.pic = 'http://' + pic
      this.rgForm.plateNo = "";
      this.rgsbDialogVisible = true;
    },
    // 关闭订单图片窗口
    rgsbDialogClose() {
      this.$refs.rgFormRef.resetFields();
      this.rgsbDialogVisible = false;
    },
    // 更新费用打折信息
    async orderDiscount(day) {
      let flag = "0";
      if (day === "first") {
        flag = this.checked1 ? "1" : "0";
      } else {
        flag = this.checked2 ? "1" : "0";
      }

      // console.log(day + '=====' + flag)
      var { data: res } = await this.$http.get(
        this.baseUrl + "parkFeeDiscount",
        {
          params: {
            day: day,
            flag: flag,
            orderNo: this.outOrder.orderNo,
            parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
          },
        }
      );
      res = JSON.parse(res);
      if (res.code === "0") {
      }
    },

    // 输入车牌退格或者清空
    deletePlateLetter(flag) {
      if (flag === "1") {
        if (this.rgForm.plateNo.length > 0) {
          this.rgForm.plateNo = this.rgForm.plateNo.substring(
            0,
            this.rgForm.plateNo.length - 1
          );
        }
      } else {
        this.rgForm.plateNo = "";
      }
    },
    // 车牌选择标签点击
    labelClick(labelContent) {
      if (this.rgForm.plateNo.length <= 7) {
        // 控制车牌长度在8以内
        this.rgForm.plateNo = this.rgForm.plateNo + labelContent;
      }
    },
    // 人工识别出场请求
    async rgsbUp() {
      // 获取当前设备编号
      this.rgForm.deviceNo = this.outEquipDeviceCode;
      this.rgForm.userId = window.localStorage.getItem("LOGIN_USERID");
      this.rgForm.parkCode = window.localStorage.getItem("LOGIN_PACKCODE");
      var { data: res } = await this.$http.get(
        this.$store.state.baseUrl + "manaulDriveOut",
        {
          params: this.rgForm,
        }
      );
      res = JSON.parse(res);
      // console.log(res)
      if (res.code === "0") {
        // 成功之后关闭页面
        this.rgsbDialogClose();
      } else {
        return this.$message.error(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.m10 {
  margin: 10px;
}
@media only screen and (max-width: 600px) {
  .pc-input {
    display: none;
  }
}
.btn2 {
  margin: 10px;
}
.item {
  flex: 1;
  flex-shrink: 0;
  min-width: 350px;
}
.video {
  width: 100%;
  height: 400px;
  flex: 1;
  border-radius: 8px;
}
.title {
  font-size: 20px;
  font-weight: bold;
}

.button-top {
  float: right;
  padding: 10px 5px;
}

.label-content {
  font-weight: bold;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
}

.demo-image__placeholder {
  .block {
    padding: 30px 0;
    text-align: center;
    border-right: 1px solid #eff2f6;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    width: 100%;
    border-right: none;
  }
  .el-image {
    width: 450px;
    height: 300px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.el-checkbox.is-bordered.el-checkbox--mini .el-checkbox__label {
  line-height: 30px !important;
  font-size: 30px !important;
}

.plate-select-div {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px;
}

.plate-select {
  font-size: 18px;
  margin-right: 5px;
  margin-top: 5px;
  &:hover {
    background-color: #4a86e8;
    cursor: pointer;
  }
}
/deep/ .el-card__header {
  padding: 10px !important;
}
</style>
