import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import Home from '../components/Home'
import Video from '../components/video/Video'
import Order from '../components/order/Order'
import Sysconfig from '../components/sys/Sysconfig'
import Park from '../components/sys/Park'
import Gate from '../components/sys/Gate'
import Equip from '../components/sys/Equip'
import Leaguer from '../components/sys/Leaguer'
import Leaguer1 from '../components/sys/Leaguer1'
import Leaguer2 from '../components/sys/Leaguer2'
import User from '../components/sys/User'
import Rule from '../components/fee/Rule'
import Scheme from '../components/fee/Scheme'
import ChargeTest from '../components/fee/ChargeTest'
import FeatureConf from '../components/sys/FeatureConf'
import Detect from '../components/order/Detect'
import Statistics from '../components/order/Statistics'
import Manaul from '../components/logmanager/Manaul'
import Region from '../components/sys/Region'
import OrderPay from '../components/order/OrderPay'
import Cars from '../components/sys/Cars'
import Carsdel from '../components/logmanager/Carsdel'
import FreeGo from '../components/logmanager/FreeGo'
import Blacklist from '../components/sys/BlackList'
import Abnormal from '../components/sys/AbnormalCar'
import Role from '../components/sys/Role'
import Carno from '../components/logmanager/Carno'
import QrPay from '../components/pay/QrPay'
import QrPayResult from '../components/pay/QrPayResult'
// import DataScreen from '../components/screen/DataScreen'
import VideoMonitor from '../components/VideoMonitor'
import Ads from '../components/Ads'
import LicensePreview from '../components/LicensePreview'
import store from '../store/index'
import Axios from 'axios'
Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login },
  { path: '/qrPay', component: QrPay },
  // { path: '/data-screen', name: 'DataScreen', meta: { permission: true }, component: DataScreen },
  { path: '/qrPayResult', name: 'QrPayResult', component: QrPayResult },
  { path: '/overview', component: Ads },
  { path: '/licensePreview', component: LicensePreview },
  { path: '/videomonitor', meta: { permission: true }, component: VideoMonitor },
  {
    path: '/',
    component: Home,
    redirect: '/home',
    meta: { permission: true },
    children: [
      { path: '/home', meta: { permission: true }, component: Video },
      { path: '/order', meta: { permission: true }, component: Order },
      { path: '/sysconfig', meta: { permission: true }, component: Sysconfig },
      { path: '/featureconf', meta: { permission: true }, component: FeatureConf },
      { path: '/park', meta: { permission: true }, component: Park },
      { path: '/gate', meta: { permission: true }, component: Gate },
      { path: '/equip', meta: { permission: true }, component: Equip },
      { path: '/leaguer', meta: { permission: true }, component: Leaguer },
      { path: '/leaguer1', meta: { permission: true }, component: Leaguer1 },
      { path: '/leaguer2', meta: { permission: true }, component: Leaguer2 },
      { path: '/black', meta: { permission: true }, component: Blacklist },
      { path: '/abnormal', meta: { permission: true }, component: Abnormal },
      { path: '/user', meta: { permission: true }, component: User },
      { path: '/rule', meta: { permission: true }, component: Rule },
      { path: '/scheme', meta: { permission: true }, component: Scheme },
      { path: '/chargetest', meta: { permission: true }, component: ChargeTest },
      { path: '/detect', meta: { permission: true }, component: Detect },
      { path: '/statistics', meta: { permission: true }, component: Statistics },
      { path: '/manaul', meta: { permission: true }, component: Manaul },
      { path: '/region', meta: { permission: true }, component: Region },
      { path: '/orderpay', meta: { permission: true }, component: OrderPay },
      { path: '/cars', meta: { permission: true }, component: Cars },
      { path: '/carsdel', meta: { permission: true }, component: Carsdel },
      { path: '/freego', meta: { permission: true }, component: FreeGo },
      { path: '/role', meta: { permission: true }, component: Role },
      { path: '/carno', meta: { permission: true }, component: Carno }
    ]
  },
  { path: '*', component: Ads }
]

const router = new VueRouter({
  mode: 'history',
  // base: '/', // 生产环境打包使用
  routes
})

// 挂载路由守卫
router.beforeEach(async (to, from, next) => {
  // 判断一下是不是有数据 加载菜单

  const baseUrl = store.state.baseUrl

  if (!baseUrl) {
    console.log('拉取基本信息')
    try {
      const url = process.env.VUE_APP_PROTOCAL + process.env.VUE_APP_HOST_ADDRESS + '/api/client/'
      const wsurl = process.env.VUE_APP_WS_PROTOCAL + process.env.VUE_APP_HOST_ADDRESS + '/parksocket'
      const config = {
        sysConfig: {
          isShowSmFree: false,
          comment: '配置为true首页监控驶离tab会显示免除费用多选框'
        }
      }
      await store.commit('intNetworkUrl', { url, wsurl }) // 客户端后台信息
      await store.commit('initSysConfig', config) // 客户端配置信息
      const tokenStr = window.sessionStorage.getItem('token')
      if (tokenStr) {
        var { data: res } = await Axios.get(url + 'parkInfo4Clent', {
          params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        await store.commit('initParkConfig', res.parkConfig)
      }
    } catch (e) {
      window.sessionStorage.clear()
      next('/overview')
      return
    }
  }

  if (to.meta.permission) {
    // 获取token
    const tokenStr = window.sessionStorage.getItem('token')

    if (!tokenStr) {
      window.sessionStorage.clear()
      next('/overview')
      return
    }
    next()
  } else {
    // 跳转建行支付外部链接或者其他不要进行身份验证的地址，不检查token
    next()
  }
})

export default router
