<template>
  <div class="container-gate">
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
        <el-button type="primary" size="small" @click="showAddDialogVisible">添加出入口</el-button>
      </template>
      <template v-slot:options="data">
        <el-button type="primary" icon="el-icon-edit" size="mini" @click="editGate(data.scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteGate(data.scope.row.pointcode)">删 除</el-button>
      </template>
    </ProTable>

    <el-dialog title="出入口" :visible.sync="addDialogVisible" width="50%" @close="addDialogClose">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="150px">
        <el-form-item label="出入口名称：" prop="pointname">
          <el-input v-model="addForm.pointname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="区域信息">
          <el-select
            placeholder="请选择"
            v-model="addForm.regioncode"
            style="width: 100%"
          >
            <el-option v-for="(item, i) in regionList " :key="i" :label="item.regionName" :value="item.regionCode"></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="通行方式">
          <el-select
            placeholder="请选择"
            v-model="addForm.pointFunc"
            style="width: 100%"
          >
            <el-option label="全部放行" value="3"></el-option>
            <el-option label="白名单放行" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGateInfo">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import ProTable from '../procomponents/ProTable.vue'

export default {
  name: 'gate',
  components: {
    ProTable
  },
  data () {
    return {
      columns: [
        {
          hideInSearch: true,
          label: '出入口名称',
          name: 'pointname',
          type: 'input'
        },
        {
          hideInSearch: true,
          label: '区域名称',
          name: 'regionName',
          type: 'input'
        },
        {
          hideInSearch: true,
          label: '通行方式',
          name: 'pointFunc',
          type: 'select',
          dataSource: [
            {
              label: '白名单放行',
              value: '1'
            },
            {
              label: '全部放行',
              value: '3'
            }
          ]
        },
        {
          width: 200,
          fixed: 'right',
          hideInSearch: true,
          label: '操作',
          name: 'options',
          slot: 'options'
        }
      ],
      baseUrl: '',
      addDialogVisible: false, // 控制添加窗口显示
      dataList: [], // 数据
      regionList: [], // 区域列表
      queryInfo: {

      },
      addForm: {
        pointcode: '',
        pointname: '',
        regioncode: '',
        pointFunc: '3'
      },
      addFormRules: {
        pointname: [
          { required: true, message: '请输入出入口名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl // 请求base地址
    this.regionList = this.$store.state.parkConfig.regionList// 区域列表
  },
  methods: {

    async request (query) {
      var { data: res } = await this.$http.get(this.baseUrl + 'querygates', {
        params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      return res
    },
    // 查询所有出入口
    async getGateInfo () {
      this.$refs.table.queryData()
    },
    // 添加窗口是否显示
    showAddDialogVisible () {
      // 设置默认值
      if (this.regionList.length > 0) {
        this.addForm.regioncode = this.regionList[0].regionCode
      }
      this.addDialogVisible = true
    },
    editGate (row) {
      this.addForm.pointcode = row.pointcode
      this.addForm.pointname = row.pointname
      this.addForm.regioncode = row.regionCode
      this.addForm.pointFunc = row.pointFunc
      this.addDialogVisible = true
    },
    // 添加窗口关闭
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
      this.addDialogVisible = false
    },
    // 删除出入口信息
    deleteGate (pointcode) {
      this.$confirm('是否删除该出入口信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var { data: res } = await this.$http.get(this.baseUrl + 'deleteGate', {
          params: { pointcode: pointcode, parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        if (res.code === '0') {
          this.$message.success(res.message)
          this.initParkStore()
          // 重新获取出入口信息
          this.getGateInfo()
        } else {
          return this.$message.error(res.message)
        }
      }).catch(() => {

      })
    },
    // 添加出入口信息
    addGateInfo () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return false
        this.$confirm('确定添加该出入口？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.addForm.parkCode = window.localStorage.getItem('LOGIN_PACKCODE')
          var { data: res } = await this.$http.get(this.baseUrl + 'addGateInfo', {
            params: this.addForm
          })
          res = JSON.parse(res)
          if (res.code === '0') {
            this.$message.success(res.message)
            this.addDialogClose()
            this.initParkStore()
            // 重新获取出入口信息信息
            this.getGateInfo()
          } else {
            return this.$message.error(res.message)
          }
        }).catch(() => {

        })
      })
    },

    // 获取停车场基本信息存储store
    async initParkStore () {
      var { data: res } = await this.$http.get(this.baseUrl + 'parkInfo4Clent', {
        params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      if (res.code === '0') {
        // console.log(res.parkConfig)
        this.$store.commit('initParkConfig', res.parkConfig)
      } else {
        return this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .container-gate{
    background-color:#f7f7f7;
    position: relative;
  }
  .el-col {
    margin: 7px 7px 7px 7px;
  }
</style>
