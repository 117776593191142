<template>
  <div class="container-equip">
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
        <el-button @click="getInnerQrCode" size="small">获取场内码</el-button>
        <el-button
          type="warning"
          @click="showConfigDialogVisible()"
          size="small"
          style="margin-right: 20px"
          >获取相机配置参数</el-button
        >
        <el-button
          type="primary"
          @click="showAddDialogVisible"
          size="small"
          style="margin-right: 20px"
          >添加相机</el-button
        >
        <el-button
          type="success"
          :loading="syncTimeLoading"
          @click="syncTime"
          size="small"
          icon="el-icon-time"
          style="margin-right: 20px"
          >校准屏幕时间</el-button
        >
      </template>
      <template v-slot:status="data">
        <el-tag
          :type="data.scope.row.status === 1 ? 'success' : 'info'"
          effect="dark"
          size="small"
          >{{ data.scope.row.status === 1 ? "在线" : "离线" }}</el-tag
        >
      </template>
      <!-- <template v-slot:enableOfflineNotice="data">
        <el-tag
          :type="data.scope.row.enableOfflineNotice ? 'success' : 'info'"
          effect="dark"
          size="small"
          >{{ data.scope.row.enableOfflineNotice ? "开启" : "关闭" }}</el-tag
        >
      </template> -->
      <template v-slot:options="data">
        <el-button size="mini" @click="showQrCode(data.scope.row)" :type="data.scope.row.videofunc === '10'? 'success' : ''">
          {{ data.scope.row.videofunc === '9'? '无牌车入场码' : '出口处收款码' }}
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-edit"
          size="mini"
          @click="editEquip(data.scope.row)"
        >
          编辑
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          @click="deleteEquip(data.scope.row.devicecode)"
        >
          删 除
        </el-button>
      </template>
    </ProTable>

    <el-dialog
      title="添加相机"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="150px"
      >
        <el-form-item label="相机序列号" prop="devicecode">
          <el-input
            v-model="addForm.devicecode"
            autocomplete="off"
            :disabled="isCodeEdit"
            placeholder="a0c2b933-221a74d2"
          ></el-input>
        </el-form-item>
        <el-form-item label="相机名称" prop="devicename">
          <el-input
            v-model="addForm.devicename"
            autocomplete="off"
            placeholder="入口相机"
          ></el-input>
        </el-form-item>
        <el-form-item label="相机方向">
          <el-select
            placeholder="请选择"
            v-model="addForm.videofunc"
            style="width: 100%"
          >
            <el-option label="入口相机" value="9"></el-option>
            <el-option label="出口相机" value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出入口信息">
          <el-select
            placeholder="请选择"
            v-model="addForm.pointcode"
            style="width: 100%"
          >
            <el-option
              v-for="(item, i) in gateList"
              :key="i"
              :label="item.pointname"
              :value="item.pointcode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="相机IP" prop="ip">
          <el-input
            v-model="addForm.ip"
            autocomplete="off"
            placeholder="192.168.1.123"
          ></el-input>
        </el-form-item>
        <el-form-item label="语音/屏显接口">
          <el-select
            placeholder="请选择"
            v-model="addForm.voiceChannel"
            style="width: 100%"
          >
            <el-option label="接口1" value="0"></el-option>
            <el-option label="接口2" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="rtsp地址" prop="rtspAddress">
          <el-input
            v-model="addForm.rtspAddress"
            autocomplete="off"
            placeholder="rtsp://192.168.1.223/stream_main"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备离线通知" prop="enableOfflineNotice">
          <el-switch v-model="addForm.enableOfflineNotice"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogClose">取 消</el-button>
        <el-button type="primary" @click="addEquipInfo">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      modal
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="相机参数配置"
      :visible.sync="configDialogVisible"
      width="80%"
    >
      <el-tabs type="card" v-model="configType" v-if="configList.length > 0">
        <el-tab-pane
          :label="item.label"
          :name="item.label"
          v-for="(item, index) in configList"
          :key="index"
        >
          <div v-html="item.value"></div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="configDialogVisible = false"
          >完 成</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      modal
      destroy-on-close
      top="0px"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :visible.sync="qrCodeData.visible"
      fullscreen
    >

      <div style="height: 15px;"></div>
      <div style="margin: 0 auto;display: flex;align-items: center;justify-content: center;" v-if="qrCodeData.visible">
        <ProQRCode :qrUrl="qrCodeURL" :qrText="this.$store.state.parkConfig.parkInfo.parkName+ qrCodePointName">
          <template v-slot:action>
            <div style="align-items: center;margin-top: 20px;" class="flex-row" v-if="qrCodeRow.videofunc != '9'">
              <div>选择支付方式：</div>
              <el-select
                v-model="qrCodeData.payType"
                class="m-2"
                size="large"
                style="width: 240px"
                @change="payTypeChange"
              >
                <el-option
                  v-for="item in payTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </template>
          <template v-slot:bottom>
            <el-button type="text" @click="qrCodeData.visible = false">取消</el-button>
          </template>
        </ProQRCode>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ProTable from "../procomponents/ProTable.vue";
import ProQRCode from "../procomponents/ProQRCode.vue";

const BASIC_URL =
  "https://epark.pengbocloud.com/thirdPark/thirdParkQRScan.do?position={position}&parkCode={parkCode}&devCode={devCode}{payType}";

const INNER_PAY_TEMPLATE =
  "https://epark.pengbocloud.com/thirdPark/thirdParkQRScan.do?position=innerPark&parkCode={parkCode}{payType}";

const payTypes = [
  { label: "原生支付", value: "&ccb=" },
  { label: "建行聚合", value: "&ccb=0101" },
  { label: "通联收银宝", value: "&ccb=0102" },
  { label: "银联商务", value: "&ccb=0103" },
];

export default {
  // name: 'equip',
  components: {
    ProTable,
    ProQRCode
  },
  data() {
    return {
      columns: [
        {
          hideInSearch: true,
          label: "相机名称",
          name: "devicename",
          width: 200,
          type: "input",
        },
        {
          hideInSearch: true,
          label: "出入口名称",
          name: "pointname",
          width: 200,
          type: "input",
        },
        {
          hideInSearch: true,
          label: "类型",
          width: 80,
          name: "videofunc",
          type: "select",
          dataSource: [
            {
              label: "入口",
              value: "9",
              type: "primary",
              effect: "dark",
            },
            {
              label: "出口",
              value: "10",
              type: "danger",
              effect: "dark",
            },
          ],
        },
        {
          hideInSearch: true,
          label: "设备状态",
          name: "status",
          width: 80,
          type: "input",
          slot: "status",
        },
        {
          hideInSearch: true,
          label: "最后心跳时间",
          name: "lastHeartBeat",
          width: 180,
          type: "dateRange",
        },
        {
          hideInSearch: true,
          label: "相机IP地址",

          name: "ip",
          type: "input",
        },
        // {
        //   hideInSearch: true,
        //   label: "离线通知",
        //   width: "200",
        //   name: "enableOfflineNotice",
        //   type: "input",
        //   slot: "enableOfflineNotice",
        // },
        {
          width: 350,
          fixed: "right",
          hideInSearch: true,
          label: "操作",
          name: "options",
          slot: "options",
        },
      ],

      isCodeEdit: false, // 相机序列号是否可以编辑
      baseUrl: "",
      addDialogVisible: false, // 控制添加窗口显示
      configDialogVisible: false,
      configType: "",
      configList: [],
      dataList: [], // 数据
      gateList: [],
      queryInfo: {},
      parkCode: "",
      addForm: {
        devicecode: "",
        devicename: "",
        pointcode: "",
        ip: "",
        rtspAddress: "",
        enableOfflineNotice: true,
        videofunc: "9",
        voiceChannel: "1",
      },
      addFormRules: {
        devicecode: [
          { required: true, message: "请输入相机名称", trigger: "blur" },
        ],
        devicename: [
          { required: true, message: "请输入相机名称", trigger: "blur" },
        ],
        ip: [{ required: true, message: "请输入相机IP", trigger: "blur" }],
      },
      syncTimeLoading: false,
      qrCodeData: {
        visible: false,
        parkCode: "",
        position: "",
        payType: "",
        name: "",
      },
      payTypes,
      qrCodeURL: '',
      qrCodePointName: '',
      qrCodeRow: {}
    };
  },
  created() {
    this.baseUrl = this.$store.state.baseUrl; // 请求base地址
    this.gateList = this.$store.state.parkConfig.gateList; // 出入口列表
  },
  methods: {
    async request(query) {
      var { data: res } = await this.$http.get(
        this.baseUrl + "queryEquipInfo",
        {
          params: { parkCode: window.localStorage.getItem("LOGIN_PACKCODE") },
        }
      );
      res = JSON.parse(res);
      return res;
    },
    showQrCode(row) {
      // console.log('row', row)
      this.qrCodeData.visible = true;
      this.qrCodeData.name = row.devicename;
      this.qrCodeData.payType = "&ccb=0101";
      this.qrCodeData.devicecode = row.devicecode;
      if (row.videofunc == "9") {
        this.qrCodeData.position = "entrance";
      } else if (row.videofunc == "10") {
        this.qrCodeData.position = "exitPark";
      } else {
        this.qrCodeData.position = "innerPark";
      }

      this.qrCodeURL = this.getUrl(this.qrCodeData.payType)
      this.qrCodePointName = row.pointname || ''
      this.qrCodeRow = row || {}
    },
    getInnerQrCode () {
      this.showQrCode({ devicename: '场内码' })
    },
    getUrl (payType) {
      // console.info(this.parkCode);
      if (this.qrCodeData.position == "innerPark") {
        // 场内付
        return INNER_PAY_TEMPLATE.replace("{parkCode}", this.parkCode).replace(
          "{payType}",
          this.qrCodeData.payType
        );
      }
      return BASIC_URL.replace("{position}", this.qrCodeData.position)
        .replace("{parkCode}", this.parkCode)
        .replace("{devCode}", this.qrCodeData.devicecode)
        .replace("{payType}", payType);
    },

    payTypeChange (payType) {
      const text = this.getUrl(payType)
      this.qrCodeURL = text
    },
    // 校准时间
    syncTime () {
      const rows = this.$refs.table.getCurrentData()
      if (rows.length === 0) {
        return
      }
      this.syncTimeLoading = true
      const deviceCodes = rows.map((r) => r.devicecode)
      const _this = this
      this.$http
        .post(
          _this.$store.state.baseUrl +
            "time-sync?parkCode=" +
            window.localStorage.getItem("LOGIN_PACKCODE"),
          deviceCodes
        )
        .then(({ data }) => {
          const result = JSON.parse(data);
          if (result.code == "0") {
            _this.$message.success(result.message);
          }
          _this.queryEquipInfo();
        })
        .finally(() => {
          _this.syncTimeLoading = false;
        });
    },

    // 查询相机列表
    async queryEquipInfo() {
      this.$refs.table.queryData();
    },
    // 删除相机信息
    async deleteEquip(devicecode) {
      this.$confirm("是否删除该相机信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var { data: res } = await this.$http.get(
            this.baseUrl + "deleteEquipInfo",
            {
              params: {
                devicecode: devicecode,
                parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
              },
            }
          );
          res = JSON.parse(res);
          // console.log(res)
          if (res.code === "0") {
            this.queryEquipInfo();
            this.initParkStore();
            return this.$message.success(res.message);
          } else {
            return this.$message.error(res.message);
          }
        })
        .catch(() => {});
    },
    // 保存添加
    addEquipInfo() {
      if (this.addForm.devicecode === "") {
        return this.$message.error("相机序列号不能为空");
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return false;
        this.addForm.parkCode = window.localStorage.getItem("LOGIN_PACKCODE");
        var { data: res } = await this.$http.get(
          this.baseUrl + "addEquipInfo",
          {
            params: this.addForm,
          }
        );
        if (res.code === 0) {
          this.$message.success(res.message);
          this.addDialogClose();
          this.initParkStore();
          // 重新获取出入口信息信息
          this.queryEquipInfo();
        } else {
          this.$message.error(res.message);
        }
        return true;
      });
    },
    // 编辑相机信息
    editEquip(row) {
      console.log(row);
      this.addForm.devicecode = row.devicecode;
      this.addForm.devicename = row.devicename;
      this.addForm.videofunc = row.videofunc;
      this.addForm.pointcode = row.pointcode;
      this.addForm.rtspAddress = row.rtspAddress;
      this.addForm.enableOfflineNotice = row.enableOfflineNotice;
      this.addForm.ip = row.ip;
      this.addForm.voiceChannel = row.voiceChannel + "";
      this.isCodeEdit = true;
      this.addDialogVisible = true;
    },
    // 控制添加窗口的显示
    showAddDialogVisible() {
      this.isCodeEdit = false;
      if (this.gateList.length > 0) {
        this.addForm.pointcode = this.gateList[0].pointcode;
      }
      this.addDialogVisible = true;
    },
    // 控制添加窗口的关闭
    addDialogClose() {
      // this.$refs.addFormRef.resetFields()
      this.addForm.devicecode = "";
      this.addForm.devicename = "";
      this.addForm.videofunc = "9";
      this.addForm.pointcode = "";
      this.addForm.rtspAddress = "";
      this.addForm.enableOfflineNotice = true;
      this.addForm.ip = "";
      this.addForm.voiceChannel = "1";
      this.addDialogVisible = false;
    },

    // 获取停车场基本信息存储store
    async initParkStore() {
      var { data: res } = await this.$http.get(
        this.baseUrl + "parkInfo4Clent",
        {
          params: { parkCode: window.localStorage.getItem("LOGIN_PACKCODE") },
        }
      );
      res = JSON.parse(res);
      if (res.code === "0") {
        // console.log(res.parkConfig)
        this.$store.commit("initParkConfig", res.parkConfig);
      } else {
        return this.$message.error(res.message);
      }
    },

    async showConfigDialogVisible() {
      this.configDialogVisible = true;

      try {
        var { data: res } = await this.$http.get(
          this.baseUrl + "getDevicesConfig",
          {
            params: { parkCode: window.localStorage.getItem("LOGIN_PACKCODE") },
          }
        );

        if (res.code === 200) {
          this.configList = res.data;
          this.configType = this.configList[0].label;
        }
      } catch (e) {}
    },

    // 设备信息上传
    async uploadDevice() {
      const url = this.baseUrl.replace("api/client", "park");
      var { data: res } = await this.$http.get(url + "uploadDeviceInfo", {
        params: { parkCode: window.localStorage.getItem("LOGIN_PACKCODE") },
      });
      res = JSON.parse(res);
      if (res.indexOf("success=true") >= 0) {
        this.$message.success("相机信息上传成功");
      } else {
        return this.$message.error("相机信息上传失败");
      }
    },
  },
  mounted() {
    this.parkCode = localStorage.getItem("LOGIN_PACKCODE");
  },
};
</script>

<style lang="less" scoped></style>
