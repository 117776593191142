<template>
  <el-container class="container">

    <el-aside :style="asideStyle" class="content-h pc-container">
      <div class="logo-wrapper">
        <img src="@/assets/images/pb_logo.png" alt="" class="logo" style="margin-left: 20px;">
        <span style="font-size: 16px;font-weight: bold;">澎泊云</span>
      </div>
      <el-menu :router="true" style="border-right:0px;" :collapse="collapseStatus" :default-active="defaultRouterName">
        <MenuLayout v-for="(item,index) in menu" :key="index" :data="item"/>
      </el-menu>
    </el-aside>

    <el-container>
      <el-main style="height: 100vh;position: relative;padding:0px 10px 10px 10px;" >
        <div class="top-menu flex-column">
          <div class="flex-row" style="align-items: center;border-bottom: 1px solid #f8f8f8;padding-bottom: 10px;">
            <el-button size="mini" :icon="drawerShow ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="changeDrawerShow()" class="mobile-container mobile-menu"></el-button>
            <div style="flex:1"></div>
            <el-button icon="el-icon-video-camera-solid" style="margin-right: 10px;" circle size="mini"  @click="allVideo()"></el-button>
            <LocalSetting />
            <div style="width: 10px;"></div>
            <el-dropdown szie="mini">
              <div class="flex-row logout" style="align-items: center;">
                <img style="height: 20px;width: 20px;" src="@/assets/images/default_avatar.png" />
                <div class="flex-column" style="margin-left: 10px;align-items: center;">
                  <span style="font-size: 14px;">{{ parkName }}</span>
                  <!-- <span style="font-size: 12px;">{{ userName }}</span> -->
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="loginOut">退出登录</el-dropdown-item>
                <el-dropdown-item divided disabled>{{ userName }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-tabs  style="margin-top: 10px;" @tab-click="handleClick" type="card" v-model="currentTab" :closable="editableTabs.length>1" @tab-remove="removeTab">
            <el-tab-pane
              v-for="item in editableTabs"
              :key="item.path"
              :label="item.name"
              :name="item.path"
              ></el-tab-pane>
          </el-tabs>
        </div>

        <transition :name="transitionName" mode="out-in">
          <keep-alive :include="keepAliveList">
            <router-view :key="$route.fullPath" ></router-view>
          </keep-alive>
          <!-- <router-view :key="$route.fullPath"></router-view> -->
        </transition>
      </el-main>
    </el-container>
    <el-drawer
      size="100%"
      :visible.sync="drawerShow"
      direction="ltr">
     <div style="height:80vh;overflow: auto;">
      <el-menu :unique-opened="false" :router="true" style="border-right:0px;width: 100%;" :collapse="collapseStatus" :default-active="defaultRouterName" @select="closeDrawer()">
        <MenuLayout v-for="(item,index) in menu" :key="index" :data="item"/>
      </el-menu>
     </div>
    </el-drawer>
  </el-container>

</template>

<script>
import MenuLayout from '@/components/menu/MenuLayout.vue'
import LocalSetting from './LocalSetting.vue'
import { getDefaultLocalSetting } from '../utils/utils'
// import Footer from './Footer.vue'
export default {
  components: {
    MenuLayout,
    LocalSetting
  },
  watch: {
    $route (to, from) {
      this.transitionName = 'el-fade-in'
      let find = false
      for (let i = 0; i < this.editableTabs.length; i++) {
        if (to.fullPath === this.editableTabs[i].path) {
          find = true
          break
        }
      }
      // console.log('watch route', to.fullPath, from.fullPath, find, this.editableTabs)
      if (!find) {
        this.editableTabs.push({
          name: this.flattenMenus.get(to.fullPath).name,
          path: to.fullPath
        })
      }
      this.currentTab = to.fullPath
      this.defaultRouterName = to.fullPath
    }
  },
  computed: {
    keepAliveList () {
      // if (process.env.NODE_ENV === 'development') {
      //   return []
      // }
      const path = []
      const keepAlive = getDefaultLocalSetting().keepAlive
      if (keepAlive === '0') {
        return []
      }

      for (let i = 0; i < this.editableTabs.length; i++) {
        path.push(this.editableTabs[i].path.replace('/', ''))
      }
      return path
    },
    asideStyle () {
      let res = ''

      if (this.collapseStatus) {
        res = 'width: auto;'
      } else {
        res = 'width: 220px'
      }
      return 'position: relative;height:100vh;border-right: 1px solid rgba(5, 5, 5, 0.06);' + res
      // return res
    }
  },
  mounted () {
    this.transitionName = 'el-fade-in'

    const route = this.$route
    this.defaultRouterName = route.fullPath
    this.baseUrl = this.$store.state.baseUrl
    this.parkName = this.$store.state.parkConfig.parkInfo.parkName

    this.userName = window.localStorage.getItem('LOGIN_USERID')
    // 查询菜单信息
    this.getMenu()
  },
  data () {
    return {
      parkName: '',
      userName: '',
      menu: [],
      defaultRouterName: '',
      currentYear: '',
      transitionName: '',
      collapseStatus: false,
      drawerShow: false,
      currentTab: null,
      editableTabs: [
      ]
    }
  },
  methods: {
    allVideo () {
      // this.$router.push('/videomonitor')

      const routeData = this.$router.resolve({ path: '/videomonitor' })
      window.open(routeData.href, '_blank')
    },
    closeDrawer () {
      this.drawerShow = false
    },
    changeDrawerShow () {
      this.drawerShow = true
    },
    changeMenuStatus () {
      this.collapseStatus = !this.collapseStatus
    },
    // 退出
    loginOut () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    async getMenu () {
      var { data: res } = await this.$http.get(this.baseUrl + 'getMenu', {
        params: { userId: window.localStorage.getItem('LOGIN_USERID'), parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      if (res.code === '0') {
        this.menu = res.data
        this.flattenMenus = this.flattenMenu(res.data)
        const router = this.$route
        const menu = this.flattenMenus.get(router.fullPath)
        if (menu) {
          this.editableTabs.push({
            name: menu.name || '默认',
            path: menu.index
          })
          this.currentTab = router.fullPath
        }
      } else {
        return this.$message.error(res.message)
      }
    },
    flattenMenu (menuData) {
      const flatMenu = new Map()
      function flatten (menuItems) {
        menuItems.forEach(menuItem => {
          const key = menuItem.index
          flatMenu.set(key, menuItem)

          if (menuItem.children && menuItem.children.length > 0) {
            flatten(menuItem.children)
          }
        })
      }
      flatten(menuData, null)
      return flatMenu
    },
    handleClick (tab, event) {
      // console.log('handleClick', tab.name)
      if (tab.name !== this.$route.fullPath) {
        this.$router.push(tab.name)
      }
    },
    removeTab (targetName) {
      const list = JSON.parse(JSON.stringify(this.editableTabs))
      for (let i = 0; i < list.length; i++) {
        if (targetName === list[i].path) {
          list.splice(i, 1)
          break
        }
      }
      this.editableTabs = list
      // console.log('removeTab', targetName, this.editableTabs)
      if (targetName === this.$route.fullPath) {
        // 当前路由
        const nextPath = list[0].path
        this.$router.push(nextPath)
        // console.log('当前路由', nextPath)
      }
    }
  }
}
</script>

<style lang="less">
  .logout:hover {
    background: #f9f9f9;
    border-radius: 6px;
    cursor: pointer;
  }
  .logout {
    padding: 4px 0px;
  }
  .top-menu {
    padding-top: 10px;
    background: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  @media only screen and (max-width: 700px) {
    .pc-container {
      display: none;
    }
  }
  .mobile-menu {
    display: block;
  }

  @media (min-width: 700px) {
    .mobile-container {
      display: none !important;
    }
  }
   /*由于 element-ui 的<el-menu>标签本身希望里面嵌套的是<el-menu-item>,<el-submenu>,<el-menu-item-group>之一，但是却嵌套了<div>,而导致收折就隐藏不了文字*/
    /*隐藏文字*/
  .el-menu--collapse  .el-submenu__title span{
      display: none;
  }
  /*隐藏 > */
  .el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
      display: none;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .logo {
    width: 32px;
    margin-right: 5px;
  }
  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    background: white;
    position: sticky;
    top: 0px;
    z-index: 2;
  }
  .action-wrapper {
    position: sticky;
    top: 50px;
    z-index: 2;
    background: white;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .header {
    // border-block-end: 1px solid rgba(5, 5, 5, 0.06);
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.6);
    padding: 0 10px;
    height: 50px !important;
    display: flex;
    align-items: center;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-block-start: 1px solid #f1f1f1;
  }
  .bottom-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.88);
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  /deep/ .el-tabs__header {
    margin: 0px 0px 0px !important;
  }
</style>
