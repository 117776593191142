<template>
  <div>
    <el-button type="primary" size="mini" @click="dialogFormVisible = true">新建收费规则</el-button>
    <el-table
      :data="tableData" >
      <el-table-column
        prop="ruleType"
        label="规则类型"
        width="280">
        <template slot-scope="scope">
          <el-tag size="medium">{{ ruleId2NameTag(scope.row.ruleId) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="expirationDate"
        label="有效期">
        <template slot-scope="scope">
          <div class="flex-row">
            <el-tag size="medium" type="warning">{{ scope.row.validStartDatetm }}</el-tag>
            <el-tag size="medium" type="warning" style="margin-left: 10px;" v-if="scope.row.validEndDatetm">{{ scope.row.validEndDatetm }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="作用的区域">
        <template slot-scope="scope">
          <el-tag size="medium" type="success">{{ areaName(scope.row.ceSubCode, scope.row.ruleApplyObj) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)" style="margin-right: 10px;">编辑</el-button>
        <el-popconfirm title="这是一段内容确定删除吗？" @confirm="handleDelete(scope.$index, scope.row)">
          <el-button
          slot="reference"
          size="mini"
          type="danger">删除</el-button>
        </el-popconfirm>
      </template>
    </el-table-column>
    </el-table>

    <el-dialog :show-close="false" modal :close-on-click-modal="false" :close-on-press-escape="false" title="收费配置" :visible.sync="dialogFormVisible" width="80%" destroy-on-close>
      <el-form ref="ruleForm" :model="form" label-width="200px" :rules="currentRulesForm">
        <!-- <el-form-item label="有效期开始"  prop="expirationDateStart">
          <el-date-picker
            v-model="form.expirationDateStart"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="有效期结束"  prop="expirationDateEnd">
          <el-date-picker
            v-model="form.expirationDateEnd"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item> -->

        <el-form-item label="有效期"  prop="expirationDate">
          <el-date-picker
            v-model="form.expirationDate"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:SS"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="规则类型" prop="ruleType">
          <el-radio :disabled="formModel==='edit'" @change="onRuleTypeChange" v-model="form.ruleType" :label="item.value" v-for="item in ruleTypes" :key="item.value">{{ item.label }}</el-radio>
        </el-form-item>
        <el-row :gutter="20">
          <el-alert :title="currentRuleType.tip" show-icon type="warning"  effect="dark" :closable="false" style="margin-bottom: 20px;"></el-alert>
          <!-- 规则1 -->
          <template v-if="form.ruleType==1">
            <el-col :span="12">
              <el-form-item label="白天开始结束时间" prop="dayTime">
                <el-time-picker
                  is-range
                  style="width: 100%;"
                  value-format="HH:mm"
                  v-model="form.dayTime"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
              </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计费时间单位" prop="chargeUnitMinute">
                <el-input v-model="form.chargeUnitMinute" placeholder="请输入" type="number">
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="白天计费单价" prop="dayChargeUnitPrice">
                <el-input v-model="form.dayChargeUnitPrice" placeholder="请输入" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="夜晚计费单价" prop="nightChargeUnitPrice">
                <el-input v-model="form.nightChargeUnitPrice" placeholder="请输入" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一天最大限额" prop="maxChargeInOneDay">
                <el-input v-model="form.maxChargeInOneDay" placeholder="请输入" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="夜晚最大限额" prop="nightLimitCharge">
                <el-input v-model="form.nightLimitCharge" placeholder="请输入" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="白天夜晚跨时段计费比重" prop="blockWeight">
                <el-input v-model="form.blockWeight" placeholder="请输入" max="1" min="0" type="number"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <!-- 规则2 -->
          <template v-if="form.ruleType == 2">
            <el-col :span="14">
              <el-form-item label="单价值" prop="unitPrice">
                <el-input v-model="form.unitPrice" placeholder="请输入" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="最大时长" prop="maxTime">
                <el-input v-model="form.maxTime" placeholder="请输入">
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
          </template>
          <!-- 规则3 -->
          <template v-if="form.ruleType == 3">
            <el-col :span="14">
              <el-form-item label="一天最大限额" prop="maxLimit">
                <el-input v-model="form.maxLimit" placeholder="请输入" type="number">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <TimeList ref="timeListRule3" :data="form.timesList" :free="false" @onChange="onTimeListChange" style="margin-left: 200px"/>
            </el-col>
          </template>
          <!-- 规则4 -->
          <template v-if="form.ruleType == 4">
            <el-col :span="14">
              <el-form-item label="免费时长" prop="firstFree">
                <el-input v-model="form.firstFree" placeholder="请输入" type="number">
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="每24小时生效" prop="loopEveryday">
                <el-switch v-model="form.loopEveryday"></el-switch>
              </el-form-item>
            </el-col>

            <el-alert type="success" :closable="false" style="margin-bottom: 20px">
              <template slot='title'>
                 <div>单次停车超过24小时超过的部分每个24小时仍然享受减免，如免费2小时，单次停车26小时会有4小时免费</div>
              </template>
            </el-alert>
            <el-col :span="24">
              <el-form-item label="24小时内时长累积" prop="sumIn24Hour">
                <!-- <el-input v-model="form.firstFree" placeholder="请输入" type="number">
                  <template slot="append">分钟</template>
                </el-input> -->
                <el-switch v-model="form.sumIn24Hour"></el-switch>
              </el-form-item>
            </el-col>
            <el-alert type="success" :closable="false" style="margin-bottom: 20px">
              <template slot='title'>
                 <div>选是则24小时内多次停车一共只有配置的免费时长，选否则每次停车都享有配置的免费时长</div>
              </template>
            </el-alert>
            <el-col :span="14">
              <el-form-item prop="firstFreeTimes" label="可使用次数（1自然日内）">
                <el-input v-model="form.firstFreeTimes" placeholder="请输入" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-alert type="success" :closable="false" style="margin-bottom: 20px">
              <template slot='title'>
                  <div>可使用次数（1自然日内）</div>
                  <div>0：无限次。</div>
                  <div>1：一天内只1次。</div>
                  <div>2：一天内只2次。</div>
                  <div>x：一天内只x次。</div>
              </template>
            </el-alert>
            <el-col :span="14">
              <el-form-item prop="firstFreeTimeOut" label="超过免费时长处理方式">
                <el-input v-model="form.firstFreeTimeOut" placeholder="请输入" type="number"></el-input>
              </el-form-item>
            </el-col>
            <el-alert type="success" :closable="false" style="margin-bottom: 20px;">
              <template slot='title'>
                  <div>超过免费时长处理方式</div>
                  <div>0：停车时间超过免费时长时，免费时长依然有效，用总停车时长减去免费停车时长，计算停车费用。</div>
                  <div>1：停车时间超过免费时长，免费时长无效，停车费用从车辆驶入时刻开始计算。</div>
              </template>
            </el-alert>
          </template>
          <!-- 规则5 -->
          <template v-if="form.ruleType == 5">
            <el-col :span="24">
              <TimeList ref="timeListRule5" :free="true" :data="form.timesList" @onChange="onTimeListChange" style="margin-left: 200px"/>
            </el-col>
          </template>
        </el-row>
        <el-form-item label="作用车型" prop="area">
          <el-cascader
          :disabled="formModel==='edit'"
          v-model="form.area"
          :options="areaListOptions"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onFormCheck" :loading="formEditing"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import TimeList from './RuleTypes/TimeList.vue'
import { areaCarType, commitForm, data2Form, getDefaultFormData, ruleId2Name } from '../../utils/FeeUtil'
export default {
  name: 'rule',
  components: {
    TimeList
  },
  computed: {
    currentRuleType () {
      for (let i = 0; i < this.ruleTypes.length; i++) {
        if (this.ruleTypes[i].value === this.form.ruleType) {
          return this.ruleTypes[i]
        }
      }
      return null
    },
    currentRulesForm () {
      let rules = null
      if (this.form.ruleType === 1) {
        rules = {
          dayTime: [
            { required: true, message: '请输入', trigger: 'change' }
          ],
          chargeUnitMinute: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          dayChargeUnitPrice: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          nightChargeUnitPrice: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          maxChargeInOneDay: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          blockWeight: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          nightLimitCharge: [
            { required: true, message: '请输入', trigger: 'blur' }
          ]
        }
      } else if (this.form.ruleType === 2) {
        rules = {
          unitPrice: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          maxTime: [
            { required: true, message: '请输入', trigger: 'blur' }
          ]
        }
      } else if (this.form.ruleType === 3) {
        rules = {
          maxLimit: [
            { required: true, message: '请输入', trigger: 'blur' }
          ]
        }
      } else if (this.form.ruleType === 4) {
        rules = {
          firstFree: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          firstFreeTimes: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          firstFreeTimeOut: [
            { required: true, message: '请输入', trigger: 'blur' }
          ]
        }
      } else if (this.form.ruleType === 5) {
        rules = {}
      }
      if (rules !== null) {
        // rules.expirationDateStart = [
        //   { required: true, message: '请输入', trigger: 'blur' }
        // ]
        rules.ruleType = [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
        rules.expirationDate = [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
        rules.area = [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
        return rules
      }
      return {}
    },
    ruleId2NameTag () {
      return function (val) {
        return ruleId2Name(val)
      }
    },
    areaName () {
      return function (ceSubCode, ruleApplyObj) {
        let name = ''
        const data = this.areaListOptions

        for (let i = 0; i < data.length; i++) {
          if (data[i].value === ceSubCode) {
            name += data[i].label
            break
          }
        }
        const data2 = areaCarType()
        for (let i = 0; i < data2.length; i++) {
          if (data2[i].value === ruleApplyObj) {
            name += '-' + data2[i].label
            break
          }
        }
        return name
      }
    }
  },
  data () {
    return {
      dialogFormVisible: false,
      formEditing: false,
      ruleTypes: [
        {
          label: '按白天夜晚分段标准计费',
          value: 1,
          tip: '基于日照市发改委提供的停车计费标准，定义以白天夜晚分段计费的计费规则，并明确各属性允许设定值范围等信息。'
        },
        {
          label: '按次计费',
          value: 2,
          tip: '按次计费，连续停车时长超过指定时长，累加计费一次。'
        },
        {
          label: '按停车时长收费',
          value: 3,
          tip: '按照停车时长收取费用，每个时长段里可以分别设定计费单价，计费单位时间，由此可以实现某个时长段按次收费（设置计费单位时间等于该时段总长，可以变相实现按次收费场景）。'
        },
        {
          label: '首停免费规则',
          value: 4,
          tip: '首次进入停车场的免费时长设定，以日照是停车规则为例，每个停车场的基础规则之上，都会设定该免费规则，一般为45分钟免费。对应日照万达广场，还进一步加了限制，即一天内只有第一次入场才享有该优惠，一个自然天内的二次入场将从入场时刻起正常计费。'
        },
        {
          label: '免费时段规则（1个自然天内）',
          value: 5,
          tip: '1个自然天内（0点0分0秒-23点59分59秒）免费停车时段设定，免费时段的设定为时间区间，如早：8：45-10：45 晚：19：00-22：00'
        }
      ],
      form: getDefaultFormData(),
      tableData: [
      ],
      areaListOptions: [],
      baseUrl: '',
      formModel: ''
    }
  },
  mounted () {
    this.baseUrl = this.$store.state.baseUrl.replace('/api/client', '')
    this.getListInfo()
    this.getRuleList()
  },
  methods: {
    // 查询所有区域信息
    async getListInfo () {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryrRegions', {
        params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      // console.log(res)
      if (res.code === '0') {
        // 查询成功，设置数据
        const list = []
        for (let i = 0; i < res.data.length; i++) {
          const item = {
            value: res.data[i].regionCode,
            label: res.data[i].regionName,
            children: areaCarType()
          }
          list.push(item)
        }
        this.areaListOptions = list
      } else {
        this.$message.error(res.message)
      }
    },
    getAreaListItem (value) {
      const data = this.areaListOptions

      for (let i = 0; i < data.length; i++) {
        if (data[i].value === value) {
          return data[i]
        }
      }
    },
    async getRuleList () {
      var { data: res } = await this.$http.post(this.baseUrl + 'chargeRule/ruleList', {
        ceCode: window.localStorage.getItem('LOGIN_PACKCODE')
      })
      this.tableData = res.data
    },
    handleClose () {
      this.dialogFormVisible = false
      this.$refs.ruleForm.resetFields()
      this.form.timesList = []
      this.formModel = null
      this.form = getDefaultFormData()
    },
    onRuleTypeChange () {
      // this.form.timesList = []
      const ref = this.$refs[`timeListRule${this.form.ruleType}`]
      if (ref) {
        ref.addList([])
      }
      this.$refs.ruleForm.clearValidate()
    },
    onTimeListChange (list) {
      this.form.timesList = list
      // console.log('onTImeListChange', list)
    },
    onFormCheck () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const ruleType = this.form.ruleType
          if (ruleType === 3 || ruleType === 5) {
            this.$refs[`timeListRule${ruleType}`].checkValidate((msg) => {
              if (!msg) {
                this.commit()
              } else {
                this.$message.error(msg)
              }
            })
          } else {
            this.commit()
          }
        } else {
          this.$message.error('请完善参数配置')
        }
        return false
      })
    },
    async commit () {
      try {
        this.formEditing = true
        const commitBody = commitForm(this.form, window.localStorage.getItem('LOGIN_PACKCODE'),
          this.form.area[0], this.form.area[1], this.getAreaListItem(this.form.area[0]).label, this.$store.state.parkConfig.parkInfo.parkName)
        var { data: res } = await this.$http.post(this.baseUrl + 'chargeRule/saveRule', {
          ...commitBody
        })
        if (res.code === 200) {
          this.$message.success('添加成功')
          this.getRuleList()
          this.handleClose()
        } else {
          this.$message.error(res.msg)
        }
        this.formEditing = false
      } catch (e) {
        this.formEditing = false
        this.$message.error(e.message)
      }
    },
    async handleDelete (index, row) {
      const data = {
        ceCode: row.ceCode,
        ceSubBlockCode: row.ceSubCode,
        ruleApplyObj: row.ruleApplyObj,
        ruleId: row.ruleId
      }
      try {
        var { data: res } = await this.$http.post(this.baseUrl + 'chargeRule/delRule', {
          ...data
        })
        console.log('handleDelete', res, data)
        if (res.code === 200) {
          this.tableData.splice(index, 1)
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    async handleEdit (index, row) {
      const data = {
        ceCode: row.ceCode,
        ceSubBlockCode: row.ceSubCode,
        ruleApplyObj: row.ruleApplyObj,
        ruleId: row.ruleId
      }

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        var { data: res } = await this.$http.post(this.baseUrl + 'chargeRule/ruleDetailList', {
          ...data
        })
        loading.close()
        if (res.code === 200) {
          const formData = data2Form(this.form, res.data)
          this.dialogFormVisible = true
          this.form = formData
          this.formModel = 'edit'
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        loading.close()
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
