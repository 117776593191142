<template>
  <div class="pay-container" style="align-items: center;">
    <div class="flex-column " style="max-width: 800px;width: 100%;margin-top: 20px;">
      <div class="card">
        <img src="@/assets/images/success.png" alt="" class="img" v-if="code == 200">
        <img src="@/assets/images/fail.png" alt="" class="img" v-else>
        <div class="title">{{data}}</div>
        <div class="tip" style="white-space: pre-line;" v-if="msg">{{ msg }}</div>
        <!-- <div class="help">无牌车出场？</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QrPayResult',
  data: function () {
    return {
      code: null,
      msg: null,
      data: null
    }
  },
  created () {
    const query = this.$route.query
    this.code = query.code || 400
    this.msg = query.msg
    this.data = query.data || '暂无信息'
  }
}
</script>

<style scoped>
  .pay-container{
    background-color: #f8f8f8;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .card {
    background: white;
    border-radius: 8px;
    margin: 5px 15px;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 12px 0px rgba(177, 180, 179, 0.2);
  }
  .img {
    width: 100px;
    margin-top: 15px;
  }
  .title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .tip {
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 12px;
  }
  .help {
    font-size: 12px;
    color: #4065eb;
    text-decoration: underline;
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
  }
</style>
