<template>
  <div class="flex-column">
    <img
        id="videoImg"
        v-if="videoStatus == 1"
        :src="videoImg"
        class="video video2"
        @click="fullScreen"
      />
      <EmptyShow :videoStatus="videoStatus" @reconnect="reconnect" msg="请确保内网打开此页面"/>
  </div>
</template>

<script>
import EmptyShow from '../EmptyShow/index.vue'
export default {
  components: {
    EmptyShow
  },
  props: {
    url: String
  },
  watch: {

    url (newValue, oldValue) {
      this.open(newValue)
    }

  },
  data () {
    return {
      videoImg: null,
      videoStatus: 0,
      fullScreenStatus: false
    }
  },
  mounted () {
    this.open(this.url)
  },
  beforeDestroy () {
    this.close()
  },

  methods: {
    close () {
      if (this.videoSocket) {
        this.videoSocket.close()
        clearTimeout(this.connectTimeout)
      }
    },
    fullScreen () {
      // if (this.fullScreenStatus) {
      //   this.enterFullScreen()
      // } else {
      //   this.exitFullscreen()
      // }
      this.enterFullScreen()
      this.fullScreenStatus = !this.fullScreenStatus
    },
    exitFullscreen () {
      const image = document.querySelector('#videoImg')
      if (image.exitFullscreen) {
        image.exitFullscreen()
      } else if (image.mozCancelFullScreen) {
        image.mozCancelFullScreen()
      } else if (image.webkitCancelFullScreen) {
        image.webkitCancelFullScreen()
      }
    },
    enterFullScreen () {
      const image = document.querySelector('#videoImg')
      if (image.requestFullscreen) {
        image.requestFullscreen()
      } else if (image.webkitRequestFullscreen) { /* Safari */
        image.webkitRequestFullscreen()
      } else if (image.msRequestFullscreen) { /* IE11 */
        image.msRequestFullscreen()
      }
    },
    reconnect () {
      // console.log('reconnect  xxx')
      this.open(this.url)
    },
    /**
     * socket 相机 视频
     * @param {*} url ws://192.168.1.120:9080/ws
     */
    open (url) {
      this.close()
      this.videoStatus = 0
      try {
        const videoSocket = new WebSocket(url)
        this.connectTimeout = setTimeout(function () {
          // console.log('WebSocket connection timeout')
          videoSocket.close()
        }, 2000)
        videoSocket.onopen = () => {
          this.videoStatus = 1
          clearTimeout(this.connectTimeout)
        }
        videoSocket.onerror = () => {
          this.videoStatus = -1
          clearTimeout(this.connectTimeout)
        }

        videoSocket.onmessage = (event) => {
          var reader = new FileReader()
          reader.onload = (event) => {
            if (event.target.readyState === FileReader.DONE) {
              this.videoImg = event.currentTarget.result
            }
          }
          reader.readAsDataURL(event.data)
        }
        this.videoSocket = videoSocket
      } catch (e) {
        this.videoStatus = -1
        clearTimeout(this.connectTimeout)
      }
    }
  }

}
</script>

<style scoped>
.video2 {
  object-fit: scale-down;
  background: #000;
}
</style>
