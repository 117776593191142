<template>
  <div class="flex-column  v-center fix-iphoneX">
    <!-- <div class="flex-row" @click="toLanneng" style="cursor: pointer;">
      <img src="@/assets/images/logo.png" alt="" style="width: 16px;">
      <span class="bottom-text">交发蓝能</span>
    </div>
    <span class="bottom-text">©{{ currentYear }} 澎泊</span> -->
  </div>
</template>

<script>
export default {
  mounted () {
    this.currentYear = new Date().getFullYear()
  },
  data () {
    return {
      currentYear: ''
    }
  },
  methods: {
    toLanneng () {
      window.location = 'https://lannengtech.com/'
    }
  }

}
</script>

<style>

</style>
