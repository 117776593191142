<template>
  <div>
    <el-menu-item
      :index="data.index"
      v-if="!data.children || data.children.length <= 0"
    >
      <i :class="data.mcss" class="mr-10"></i>
      <span slot="title">{{ data.name }}</span>
    </el-menu-item>
    <el-submenu v-else :index="data.index">
      <template slot="title">
        <i :class="data.mcss" class="mr-10"></i>
        <span slot="title">{{ data.name }}</span>
      </template>
      <MenuLayout v-for="(item, index) in data.children" :key="index" :data="item"/>
    </el-submenu>

  </div>
</template>

<script>
import MenuLayout from './MenuLayout.vue'
export default {
  name: 'MenuLayout',
  props: ['data'],
  components: {
    MenuLayout
  }

}
</script>
<style scoped>
  .mr-10 {
    margin-right: 10px;
  }
</style>
