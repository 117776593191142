<template>
  <div :class="'flex-column main fade-in ' + showLayoutAnim">
    <div class="flex-column v-center flex1 vh100 h-center">
        <div  class="flex-column login-form-pc login-form">
          <div class="name">PengboCloud</div>
          <div class="sub-name">澎泊云城市级智慧停车平台</div>
          <div></div>
          <el-form ref="loginFromRef" :model="loginForm" :rules="lofinFormRules" label-width="0px" class="login_form">
            <!-- 用户名 -->
            <el-form-item prop="parkCode">
                <el-input v-model="loginForm.parkCode" placeholder="请输入停车场编码" prefix-icon="iconfont iconcheliang-" clearable></el-input>
            </el-form-item>
            <!-- 用户名 -->
            <el-form-item prop="userId">
                <el-input v-model="loginForm.userId" placeholder="请输入用户名" prefix-icon="iconfont iconuser" clearable></el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
                <el-input v-model="loginForm.password" placeholder="请输入密码" prefix-icon="iconfont iconpassword" type="password" @keyup.enter.native="login" clearable></el-input>
            </el-form-item>
            <el-form-item @keyup.enter.native="login">
                <el-checkbox v-model="remember.userId" disabled v-if="false">记住用户名</el-checkbox>
                <el-checkbox v-model="remember.password">记住密码</el-checkbox>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item class="btns">
                <el-button style="width: 100%;" type="primary" @click="login" :loading='loginLoading'>登 录</el-button>
            </el-form-item>
          </el-form>
        </div>
    </div>
    <Footer></Footer>
    <div style="height: 10px;"></div>
  </div>
</template>
<script>
import Footer from '../components/Footer.vue'

export default {
  components: {
    Footer
  },
  mounted () {
    const that = this
    setTimeout(() => {
      that.showLayoutAnim = 'fade-in-show'
    }, 100)
    this.initWebParams()
    // 读取缓存
    this.getLocalStorage()
  },
  data () {
    return {
      showLayoutAnim: '',
      loginLoading: false,
      configData: {},
      remember: {
        userId: true,
        password: false
      },
      baseUrl: '',
      // 登录表单数据对象
      loginForm: {
        parkCode: '',
        userId: '',
        password: ''
      },

      // 登录表单验证规则
      lofinFormRules: {
        parkCode: [
          { required: true, message: '停车场编码不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }]
      }

    }
  },
  methods: {
    // 登录方法
    login () {
      this.loginLoading = true
      try {
        this.$refs.loginFromRef.validate(async valid => {
          if (!valid) {
            this.loginLoading = false
            return false
          }
          try {
            let { data: res } = await this.$http.get(this.baseUrl + 'login?', {
              params: { parkCode: this.loginForm.parkCode, userId: this.loginForm.userId, password: this.loginForm.password }
            })
            res = JSON.parse(res)
            this.loginLoading = false
            if (res.code === '0') {
              this.$message.success(res.message)
              window.sessionStorage.setItem('token', res.token)// 将token保存到sessionStorage中
              window.sessionStorage.setItem('parkCode', this.loginForm.parkCode)// 停车场编码
              window.sessionStorage.setItem('userId', this.loginForm.userId)// 登录名存session
              // 将停车场编码保存到本地存储里
              window.localStorage.setItem('LOGIN_PACKCODE', this.loginForm.parkCode)

              // 将用户名密码存localstorage
              if (this.remember.userId) {
                window.localStorage.setItem('LOGIN_USERID', this.loginForm.userId)
              } else {
                if (window.localStorage.getItem('LOGIN_USERID') !== null) {
                  window.localStorage.removeItem('LOGIN_USERID')
                }
              }
              if (this.remember.password) {
                window.localStorage.setItem('LOGIN_PASSWORD', this.loginForm.password)
              } else {
                if (window.localStorage.getItem('LOGIN_PASSWORD') !== null) {
                  window.localStorage.removeItem('LOGIN_PASSWORD')
                }
              }
              this.init()
            } else {
              this.$message.error(res.message)
            }
          } catch (e) {
            this.$message.error(e.message)
          }
          this.loginLoading = false
          return true
        })
      } catch (e) {
        this.loginLoading = false
        return false
      }
    },
    // 页面初始化方法
    // 获取停车场基本信息，存储store
    async init () {
      var { data: res } = await this.$http.get(this.baseUrl + 'parkInfo4Clent', {
        params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      if (res.code === '0') {
        // console.log(res.parkConfig)
        this.$store.commit('initParkConfig', res.parkConfig)
        this.$router.push('/home')// 跳转主页
      } else {
        this.$message.error(res.message)
        this.$router.push('/home')// 跳转主页
      }
    },
    // 网页端初始化参数加载
    async initWebParams () {
      const config = {
        sysConfig: {
          isShowSmFree: false,
          comment: '配置为true首页监控驶离tab会显示免除费用多选框'
        }
      }
      this.configData = config
      this.initParams()
    },
    // 参数初始化
    initParams () {
      this.baseUrl = this.$store.state.baseUrl
    },
    // 进入页面读取缓存
    getLocalStorage () {
      // 记住用户名密码逻辑
      const parckcodeLocal = window.localStorage.getItem('LOGIN_PACKCODE')
      const usernameLocal = window.localStorage.getItem('LOGIN_USERID')
      const passwordLocal = window.localStorage.getItem('LOGIN_PASSWORD')
      if (usernameLocal !== null) {
        this.loginForm.userId = usernameLocal
        this.remember.userId = true
      }
      if (passwordLocal !== null) {
        this.loginForm.password = passwordLocal
        this.remember.password = true
      }
      // 设定停车场编码
      this.loginForm.parkCode = parckcodeLocal

      // URL参数里带请求值直接填入form表单
      const qParkCode = this.$route.query.parkCode
      const qUserId = this.$route.query.userId
      const qPassword = this.$route.query.password
      if (typeof qParkCode !== 'undefined' && qParkCode !== null && qParkCode !== '') {
        this.loginForm.parkCode = qParkCode
      }
      if (typeof qUserId !== 'undefined' && qUserId !== null && qUserId !== '') {
        this.loginForm.userId = qUserId
      }
      if (typeof qPassword !== 'undefined' && qPassword !== null && qPassword !== '') {
        this.loginForm.password = qPassword
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login-form-pc {
  width: 400px;
}
 @media only screen and (max-width: 600px) {
    .login-form {
      width: 90%;
    }
  }
.name {
  align-self: center;
  font-size: 28px;
  font-weight: bold;
}
.sub-name{
  align-self: center;
  font-size: 13px;
  margin-bottom: 20px;
}
.main {
  background-image: url('~@/assets/images/bg.png');
  overflow: auto;
  height: 100vh;
  width: 100vw;
  background-size:100% 100%
}
.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* 过渡属性：透明度，1秒的渐变时间，缓入缓出效果 */
}

.fade-in-show {
  opacity: 1;
}
</style>
