<template>
  <div class="flex-column v-center">
    <div class="flex-row">
      <div v-for="(item, index) in inputs" :key="index" class="flex-row v-center">
        <el-input @input="(e) => onValueChange(e, index)" class="input" v-model="inputs[index]" placeholder="FFFFF" :ref="'input_' + index"></el-input>
        <div class="line" v-if="index < inputs.length - 1"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      active: false,
      inputs: ['', '', '', '', '', '']
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.input_0[0].focus()
    })
  },
  methods: {
    onValueChange (data, index) {
      if (data.length === 35) {
        // 复制文本处理 转大写
        this.inputs = data.split('-')
        return
      }
      let e = data
      if (data.length >= 5) {
        // 截取前e的五位
        e = data.slice(0, 5)
      }
      this.inputs[index] = e
      if (e.length >= 5) {
        let targetIndex = index + 1
        if (targetIndex > this.inputs.length - 1) {
          targetIndex = this.inputs.length - 1
        }

        const nextRef = this.$refs[`input_${targetIndex}`][0]
        if (nextRef) {
          nextRef.focus()
        }
      } else if (e.length === 0) {
        let targetIndex = index - 1
        if (targetIndex < 0) {
          targetIndex = 0
        }
        const nextRef = this.$refs[`input_${targetIndex}`][0]
        if (nextRef) {
          nextRef.focus()
        }
      }
    }
  },
  watch: {
    inputs (val) {
      this.$emit('onChange', val.join(''))
    }
  }
}
</script>
<style scoped>
.line {
  width: 10%;
  height: 1px;
  background-color: #999;
}

/deep/ .el-input__inner {
  text-align: center !important
}
</style>
