import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sysConfig: { // 客户端配置

    },
    // 请求前缀
    baseUrl: '',
    wsUrl: '',
    // 停车场配置信息
    parkConfig: {
      parkInfo: {},
      regionList: [],
      gateList: [],
      equipList: [],
      // 首页显示 “免除今日费用”
      showTodayButton: false,
      // 是否激活菜单了
      active: false
    }
  },
  mutations: {
    // 停车场配置信息
    initParkConfig (state, data) {
      data.active = data.active || true
      state.parkConfig = data
    },
    // 客户端后台网络配置信息
    async intNetworkUrl (state, data) {
      state.baseUrl = data.url
      state.wsUrl = data.wsurl
    },
    // 客户端配置
    initSysConfig (state, data) {
      state.sysConfig = data
    },
    updateActive (state, active) {
      state.parkConfig.active = active
    }
  },
  actions: {
  },
  modules: {
  }
})
