<template>
  <div>
    <el-form :model="form" ref="timeListForm">
      <el-form-item label="">
        <el-button type="primary" @click="addItem">添加一个时间段</el-button>
      </el-form-item>
      <!-- 配置开始结束时间 -->
      <el-alert style="margin-top: 10px;margin-bottom: 20px;" title="前1小时:请设置开始结束时间为0-60" show-icon type="success" :closable="false"></el-alert>
      <el-row :gutter="24" v-for="(item, index) in form.times" :key="index">
        <el-col :span="4">
          <el-form-item label="开始分钟" :prop="'times.' + index + '.dateRangeStart'" :rules="rules.dateRangeStart">
              <el-input placeholder="请输入" v-model="item.dateRangeStart"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="结束分钟" :prop="'times.' + index + '.dateRangeEnd'" :rules="rules.dateRangeEnd">
            <el-input placeholder="请输入" v-model="item.dateRangeEnd"></el-input>
          </el-form-item>
        </el-col>
        <template v-if="!free">
          <el-col :span="6">
            <el-form-item label="计费单位（分钟）" :prop="'times.' + index + '.chargeUnit'" :rules="rules.chargeUnit">
              <el-input placeholder="请输入" v-model="item.chargeUnit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="单价" :prop="'times.' + index + '.timeUnit'" :rules="rules.timeUnit">
              <el-input placeholder="请输入" v-model="item.timeUnit"></el-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="3">

          <el-form-item label="操作">
            <div style="width: 100%;">
              <el-button style="width: 100%;text-align: left;padding: 0;color: #f56c6c;"
                @click="delItem(index)"
                type="text"
                circle
              >删除</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'TimeList',
  props: ['free', 'data'],
  data () {
    return {
      form: {
        times: []
      },
      rules: {
        dateRangeStart: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        dateRangeEnd: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        chargeUnit: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        timeUnit: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    // this.$emit('onChange', [])
    this.form.times = this.data
  },
  methods: {
    delItem (index) {
      var list = this.form.times
      list.splice(index, 1)
      this.onListChange(list)
      this.form.times = list
    },
    addItem () {
      var item = {
        // dateRange: [null, null],
        dateRangeStart: null,
        dateRangeEnd: null,
        chargeUnit: null,
        timeUnit: null
      }
      var list = this.form.times
      list.push(item)
      this.onListChange(list)
      this.form.times = list
    },
    onListChange (list) {
      this.$emit('onChange', list)
    },
    addList (list) {
      this.form.times = list
    },
    /**
     * 检查是否都是可行的
     */
    checkValidate (fn) {
      this.$refs.timeListForm.validate((valid) => {
        if (valid) {
          if (this.form.times.length <= 0) {
            fn('至少添加一条数据')
          } else {
            fn(null)
          }
          return true
        } else {
          fn('请完善配置时间范围')
          return false
        }
      })
    }
  }
}
</script>
