<template>
  <div>
    <el-tag v-if="data && data.color" :color="data && data.color" :size="data && data.size?data.size:'small'" :effect="data && data.effect?data.effect:'plain'">
      {{ data? data.label : '-' }}
    </el-tag>
    <el-tag v-else :type="data && data.type?data.type:'info'" :size="data && data.size?data.size:'small'" :effect="data && data.effect?data.effect:'plain'">
      {{ data? data.label : '-' }}
    </el-tag>
  </div>
</template>

<script>
export default {
  props: ['data']

}
</script>

<style>

</style>
