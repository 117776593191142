<template>
  <div :class="'flex-row h-center v-center ad-bg fade-in ' + showLayoutAnim" :style="'background-color: ' + bgColor">
    <div style="max-width: 55%;max-height: 70vh;overflow: auto;min-width: 320px;" class="ad-info">

      <div class="ad-name">澎泊云</div>
      <div class="ad-name-en">PENGBO CLOUD</div>
      <div class="detail" style="margin-top: 25px;text-indent: 35px;">
        通过汲取2017年以来运营路内外停车的经验，深耕智慧停车行业场景，以解决停车运营痛点为基础，打造出包括智慧停车管理平台、智慧停车运营平台、车位管控设备、停车诱导系统等在内的智慧停车系列生态产品。
      </div>
      <div class="detail" style="text-indent: 35px;">
        2022年推出颠覆性路外封闭停车场管理平台“澎泊”，实现停车场云上管理，停车运营永远在线。
      </div>
      <div style="margin-top: 10px;"></div>
      <div class="flex-row detail" style="align-items: baseline;font-size: 16px;color: #333;font-weight: bold;">
        当前已接入停车场：<CountAnim :num="parkSize"></CountAnim>个
      </div>
      <div class="flex-row detail" style="align-items: baseline;font-size: 16px;color: #333;font-weight: bold;">
        累计处理停车订单：<CountAnim :num="orderSize"></CountAnim>条
      </div>
      <!-- <div class="flex-row detail" style="align-items: baseline;">
        客户停车收入：<CountAnim :num="32324"></CountAnim>元
      </div> -->
      <div style="margin-top: 10px;"></div>
      <div class="flex-row" style="flex-wrap: wrap;">
        <div class="more" @click="toLogin">登录</div>
        <div class="more" @click="contact">注册</div>
        <div class="more" @click="about">了解更多+</div>
      </div>
    </div>
    <img src="@/assets/images/pb_logo.png" alt="" class="logopng">
    <div class="copyright flex-column">
      <div class="flex-row" style="flex-wrap: wrap;align-items:center">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" class="copyright-a" style="margin-right: 10px;">鲁ICP备18000979号-9</a>
        <img src="@/assets/images/jh.png" alt="" style="width: 16px;height:16px">
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37110202371158" class="copyright-a">鲁公网安备37110202371158号</a>
      </div>
      <div class="flex-row" style="flex-wrap: wrap;">
        <a href="/licensePreview?img=B1-20234932.jpg" class="copyright-a" style="margin-right: 10px;">增值电信业务经营许可证：B1-20234932</a>
        <a href="/licensePreview?img=B2-20231031.jpg" class="copyright-a">鲁B2-20231031</a>
      </div>
    </div>

    <el-dialog
      title="注册"
      :visible.sync="registerDialog"
      destroy-on-close
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="375px"
      center>
      <el-form ref="form" :model="form" :rules="registerRules" label-width="95px" size="small">
        <el-form-item label="停车场名称" prop="parkName">
          <el-input v-model="form.parkName"></el-input>
        </el-form-item>
        <el-form-item label="停车场地址" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="principal">
          <el-input v-model="form.principal"></el-input>
        </el-form-item>
        <el-form-item label="泊位数" prop="berthCount">
          <el-input v-model="form.berthCount"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <!-- @click.prevent="removeDomain(domain)" -->
          <div class="flex-row">
            <el-input v-model="form.phone"></el-input><el-button :loading="smsSending" :disabled="smsTime<60" type="text" style="margin: 0px 10px;"  @click.prevent="sendSMSCode()">{{smsTime>=60?'发送短信': smsTime+'秒'}}</el-button>
          </div>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
          <el-input v-model="form.smsCode"></el-input>
        </el-form-item>
        <el-form-item  prop="agree">
          <el-checkbox-group v-model="form.agree" >
            <el-checkbox label="我已阅读并同意《使用协议》" name="agree"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="registerDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="registerOk" :loading="registerLoading">立即注册</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="375px"
      center
      top="25vh"
      title="注册成功"
      :visible.sync="registerSuccessDialog">
      <el-alert
        title="请注意妥善保管用户名和密码"
        type="error"
        :closable="false"
        >
      </el-alert>
      <div class="flex-column" style="margin-top: 20px;">
        <div>
          停车场编号：{{ registerSuccessInfo.parkCode }}
        </div>
        <div>
          用户名称：{{ registerSuccessInfo.phone }}
        </div>
        <div>
          用户密码：{{ registerSuccessInfo.password }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="toLogin()">立即登录</el-button>
    </span>
    </el-dialog>
  </div>

</template>

<script>
import CountAnim from './CountAnim.vue'
export default {
  components: {
    CountAnim
  },
  data () {
    return {
      bgColor: '#fff',
      parkSize: 0,
      orderSize: 0,
      showLayoutAnim: '',
      registerDialog: false,
      form: {
        agree: []
      },
      smsTime: 60,
      smsInterval: 0,
      smsSending: false,
      registerLoading: false,
      registerSuccessDialog: false,
      registerSuccessInfo: {},
      registerRules: {
        parkName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        principal: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        berthCount: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        agree: [
          { required: true, message: '请同意协议', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.baseUrl = this.$store.state.baseUrl.replace('/api/client', '')
    // const colors = [
    //   '#29f090', '#e1de13', '#e15b13', '#1335e1', '#8f13e1'
    // ]
    // const index = Math.floor(Math.random() * colors.length)
    // this.bgColor = colors[index]
    const that = this
    setTimeout(() => {
      that.showLayoutAnim = 'fade-in-show'
    }, 100)
    this.checkData()
    this.checkIntervalId = setInterval(() => {
      that.checkData()
    }, 10 * 1000)
  },
  beforeDestroy () {
    clearInterval(this.checkIntervalId)
    clearInterval(this.smsInterval)
  },
  methods: {
    async checkData () {
      try {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'checkAdData')
        if (res.code === '0') {
          this.parkSize = res.data.parkSize
          this.orderSize = res.data.orderSize
        }
      } catch (e) {}
    },
    toLogin () {
      this.$router.push('/login')
    },
    contact () {
      // window.location = 'https://lannengtech.com/contact/'
      this.registerDialog = true
    },
    async sendSMSCode () {
      if (this.smsTime < 60) {
        return
      }
      if (this.form.phone && this.form.phone.length === 11) {
        this.smsSending = true
        const res = await this.sendCode()
        this.smsSending = false
        if (res) {
          this.$message.error(res)
          this.smsTime = 60
          return
        }
        this.smsTime -= 1
        this.startSMSTimer()
      } else {
        this.$message.error('请填写正确的手机号码')
      }
    },
    async sendCode () {
      try {
        var { data: res } = await this.$http.post(this.baseUrl + 'park/smsAuth', {
          phone: this.form.phone
        })
        if (res.code === 200) {
          this.$message.success('发送成功')
          return null
        } else {
          return res.msg
        }
      } catch (e) {
        return e.message
      }
    },
    startSMSTimer () {
      const that = this
      this.smsInterval = setInterval(() => {
        that.smsTime -= 1
        if (that.smsTime <= 0) {
          that.smsTime = 60
          clearInterval(that.smsInterval)
        }
      }, 1000)
    },
    registerOk () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = { ...this.form }
          delete params.agree
          // console.log('register', params, this.form)
          this.registerLoading = true

          try {
            var { data: res } = await this.$http.post(this.baseUrl + 'park/regist', params)
            if (res.code === '0') {
              this.registerSuccessInfo = res.userInfo
              this.showRegisterSuccess()
            } else {
              this.registerLoading = false
              this.$message.error(res.message)
            }
          } catch (e) {
            this.registerLoading = false
            this.$message.error(e.message)
          }
        }
        return false
      })
    },
    showRegisterSuccess () {
      this.registerSuccessDialog = true
      this.registerDialog = false
      this.registerLoading = false
      this.form = {
        agree: []
      }
      // // 判断注册成功后 跳转登陆页面
      this.$message.success('注册成功')
    },
    about () {
      window.location = 'https://lannengtech.com/'
    }
  }

}
</script>

<style scoped>
  .logopng{
    position: fixed;
    left: 40px;
    top: 40px;
    width: 10vw;
  }
  .more {
    margin-right: 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333;
    border-radius: 35px;
    cursor: pointer;
    height: 44px;
    padding: 0 18px;
    width: 130px;
    color: #333;
    font-weight: bold;
  }
  .ad-name {
    color: #333;
    font-size: 36px;
    font-weight: bold;
  }
  .ad-name-en {
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  .detail {
    color: #666;
    font-size: 17px;
  }
  .num {
    color: #0a55e0;
    font-size: 38px;
    font-weight: bold;
    margin: 0;
  }
  .item {
    margin-right: 20px;
  }
  .tip {
    color: #fff;
    font-size: 18px;
  }
  .ad-bg {
    background-image: url('~@/assets/images/ad_bg.png');
    overflow: auto;
    height: 100vh;
    width: 100vw;
    /* background-size:100% 100%; */
    background-size: cover;
  }
  .ad-info {
    backdrop-filter: blur(52px);
    -webkit-backdrop-filter: blur(52px);
    padding: 25px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.15);
    box-shadow:  4px 4px 4px rgba(0, 0, 0, 0.15);
  }
  .ads-bttom {
    width: 100vw;
    position: fixed;
    bottom: 0;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .copyright {
    position: fixed;
    left: 20px;
    bottom: 10px;
  }
  .copyright-a {
    text-decoration: none;
    display: inline-block;
    outline: none;
    color: #666;
    font-size: 15px;
    cursor: pointer;
  }
  .copyright-a:hover {
    color: #000;
    text-decoration: underline;
  }
</style>
