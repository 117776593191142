<template>

  <DataScreenActivation>
    <template slot="components">
      <el-form label-width="100px" class="flex-row search-wrapper" size="mini">
        <el-row :gutter="10" style="width: 100%;">
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="7">
            <el-form-item label="选择时间范围">
              <el-date-picker
                :clearable="false"
                style="width: 100%;"
                v-model="dateRange"
                type="daterange"
                value-format="yyyy-MM-dd HH:mm:SS"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="7">
            <el-form-item>
                <el-button type="primary" @click="parkStatistics" :loading="searchLoading">查询</el-button>
                <el-button @click="resetData" :loading="searchLoading">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row :gutter="10" style="width: 100%;">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-card shadow="always" class="card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600;">停车场订单金额</span>
            </div>
            <chart ref="chart1" :options="option" :auto-resize="true" style="height:370px;"></chart>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-card shadow="always" class="card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600;">停车场订单统计数据</span>
            </div>
            <div style="font-weight: 300; padding: 10px; line-height: 30px;">
              订单总数：<span class="cnt">{{orderTotals}}</span> <br>
              0元订单：<span class="cnt">{{option.series[0].data[0]}}</span> <br>
              0~5元订单：<span class="cnt">{{option.series[0].data[1]}}</span> <br>
              5~10元订单：<span class="cnt">{{option.series[0].data[2]}}</span> <br>
              10~20元订单：<span class="cnt">{{option.series[0].data[3]}}</span><br>
              20~50元订单：<span class="cnt">{{option.series[0].data[4]}}</span><br>
              50元以上订单：<span class="cnt">{{option.series[0].data[5]}}</span><br>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-card shadow="always" class="card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600;">停车场收入金额</span>
            </div>
            <chart ref="chart2" :options="option1" style="height:370px;"></chart>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
          <el-card shadow="always" class="card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600;">停车场收入统计数据（不含退款）</span>
            </div>
            <div style="font-weight: 300; padding: 10px; line-height: 30px;">
              总收入金额：<span class="cnt">{{incomeTotals}}</span> <br>
              <!-- <div>现金收入：<span class="cnt">200.00</span></div>
              <div>微信收入：<span class="cnt">100</span></div>
              <div>支付宝收入：<span class="cnt">88</span></div>
              <div>ETC: <span class="cnt">911</span></div>
              <div>会员余额: <span class="cnt">33</span></div>
              <div>停车卡: <span class="cnt">556</span></div> -->
              <div v-for="item in this.option1.series[0].data" :key="item.name">
                {{item.name}}：<span class="cnt">{{item.value}}</span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
  </template>
  </DataScreenActivation>

</template>
<script>
import { formatDataHMS, formatParamsDate } from '../../utils/utils'
import DataScreenActivation from '../activations/DataScreenActivation.vue'
export default {
  name: 'statistics',
  components: {
    DataScreenActivation
  },
  data () {
    return {
      searchLoading: true,
      dateRange: [
        formatParamsDate(new Date(), -1),
        formatParamsDate(new Date(), -1, '23:59:59')
      ],
      option: {
        legend: {},
        tooltip: {},
        xAxis: {
          type: 'category',
          data: ['0元', '0~5元', '5-10元', '10-20元', '20-50元', '50元以上']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [0, 0, 0, 0, 0, 0],
          type: 'bar'
        }]
      },
      option1: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 10,
          data: []
        },
        series: [
          {
            name: '停车收入',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [

            ]
          }
        ]
      }
    }
  },
  // 进入页面执行逻辑
  created () {
    this.parkStatistics()
  },
  methods: {
    resetData () {
      this.dateRange = [
        formatParamsDate(new Date(), -1),
        formatParamsDate(new Date(), -1, '23:59:59')
      ]
      this.parkStatistics()
    },
    async parkStatistics () {
      this.searchLoading = true
      let params = {
        parkCode: window.localStorage.getItem('LOGIN_PACKCODE')
      }
      if (this.dateRange.length === 2) {
        params = { ...params, start: this.dateRange[0], end: formatDataHMS(this.dateRange[1]) }
      }
      try {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'parkStatistics', {
          params: params
        })
        res = JSON.parse(res)
        if (res.code === '0') {
          this.option.series[0].data = res.data.ddtj
          this.option1.legend.data = res.data.incomeCate
          this.option1.series[0].data = res.data.incomeData
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        this.$message.error(e.message)
      }
      this.searchLoading = false
    }

  },
  computed: {
    orderTotals: function () {
      let orders = 0
      this.option.series[0].data.map(function (i) {
        orders = orders + i
      })
      return orders
    },
    incomeTotals: function () {
      let income = 0
      this.option1.series[0].data.map(function (i) {
        income = income + i.value
      })
      return income
    }
  }

}
</script>

<style lang="less" scoped>
.card {
  height: 400px;
  margin: 5px 0px;
  padding: 5px;
}
.cnt {
  font-weight: bold;
}
/deep/ .el-card__header {
    padding: 10px !important;
}
.search-wrapper {
  flex-wrap: wrap;
  background: #f8f8f8;
  padding-top: 15px;
  padding-left: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
}
</style>
