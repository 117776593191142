<template>
  <div>
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
          <el-button type="success" @click="exportList" size="small">导&nbsp;&nbsp;出</el-button>
      </template>
      <template v-slot:userid="data">
        <el-tag effect="dark" type="success">{{ data.scope.row.userName }}</el-tag>
      </template>
      <template v-slot:options="data">
        <el-button type="primary" icon="el-icon-picture" size="mini" @click="showImgDialogVisible(data.scope.row.inImg,data.scope.row.outImg)">查看图片</el-button>
      </template>
    </ProTable>
    <el-dialog title="订单图片" :visible.sync="imgDialogVisible" width="90%" @close="imgDialogClose">
      <div class="flex-row order-pic">
        <div class="flex-column order-pic-item">
            <span class="demonstration">入场</span>
            <el-image :src=images.inImg class="img"></el-image>
          </div>
          <div class="flex-column order-pic-item">
            <span class="demonstration">出场</span>
            <el-image :src=images.outImg class="img"></el-image>
          </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogClose">关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { formatParamsDate } from '@/utils/utils'
import { exportFileV2 } from '../../utils/ExportFileDownload'
import ProTable from '../procomponents/ProTable.vue'
export default {
  name: 'freego',
  components: {
    ProTable
  },
  data () {
    return {
      columns: [
        {
          label: '车牌号码',
          name: 'carno',
          type: 'input',
          dataFormater: (value) => {
            return {
              plateNo: value
            }
          }
        },
        {
          hideInSearch: true,
          label: '驶入时间',
          name: 'inTime',
          type: 'dateRange'
        },
        {
          hideInSearch: true,
          label: '驶离时间',
          name: 'outTime',
          type: 'dateRange'
        },
        {
          label: '操作时间',
          name: 'logTime',
          type: 'dateRange',
          defaultValue: [
            formatParamsDate(new Date(), -1),
            formatParamsDate(new Date(), 0, '23:59:59')
          ],
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1]
            }
          }
        },
        {
          hideInSearch: true,
          label: '区域',
          name: 'regionName',
          type: 'input'
        },
        {
          label: '处理人',
          name: 'userid',
          type: 'select',
          slot: 'userid',
          dataSource: []
        },
        {
          hideInSearch: true,
          label: '操作',
          name: 'options',
          slot: 'options'
        }
      ],
      imgDialogVisible: false, // 控制图片窗口显示
      baseUrl: '',
      images: {
        inImg: '',
        outImg: ''
      }
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl// 请求base地址
    this.queryUserList()
  },
  methods: {
    async request (query) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryFreeGoList', {
        params: query
      })
      res = JSON.parse(res)
      return res
    },
    async queryUserList () {
      try {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryUserList', {
          params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        const tmp = [{ userid: '', userName: '全部' }]
        const userList = tmp.concat(res.data)

        const list = []
        for (let i = 0; i < userList.length; i++) {
          const temp = {
            label: userList[i].userName,
            value: userList[i].userid
          }
          list.push(temp)
        }
        this.columns[5].dataSource = list
      } catch (e) {}
    },

    // 打开订单图片窗口
    showImgDialogVisible (inImg, outImg) {
      // 图片
      this.images.inImg = inImg
      this.images.outImg = outImg
      this.imgDialogVisible = true
    },

    // 关闭订单图片窗口
    imgDialogClose () {
      this.images.inImg = ''
      this.images.outImg = ''
      this.imgDialogVisible = false
    },

    // 导出列表
    exportList () {
      const params = this.$refs.table.paramsFilter()
      exportFileV2(this, `${this.$store.state.baseUrl}exportFreeGoList`, params)
    }
  }
}
</script>

<style lang="less" scoped>
  .img {
    width: 90%;
    height: 300px;
  }
  .order-pic-item {
    width: 50%;
    align-items: center;
    min-width: 300px;
  }
  .demonstration {
    margin: 5px;
  }
  .order-pic {
    flex-wrap: wrap;
  }

</style>
