<template>
  <div>
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
        <el-button type="success" @click="exportOrders" size="small"
          >导&nbsp;&nbsp;出</el-button
        >
      </template>
      <!-- <template v-slot:plateNo="data">
        <el-tag effect="dark" type="success" size="small" >{{ data.scope.row.plateNo }}</el-tag>
      </template> -->
      <template v-slot:options="data">
        <el-button
          type="primary"
          icon="el-icon-more"
          size="mini"
          @click="
            showImgDialogVisible(
              data.scope.row.inImg,
              data.scope.row.outImg,
              data.scope.row.orderNo,
              data.scope.row.orderFee
            )
          "
          >更多</el-button
        >
      </template>
    </ProTable>
    <el-dialog
      title="订单详情"
      :visible.sync="imgDialogVisible"
      width="90%"
      @close="imgDialogClose"
    >
      <!-- <div class="demo-image__placeholder">
        <div class="block">
          <span class="demonstration">入场</span>
          <el-image :src=images.inImg ></el-image>
        </div>
        <div class="block">
          <span class="demonstration">出场</span>
          <el-image :src=images.outImg ></el-image>
        </div>
      </div> -->

      <div class="flex-row order-pic">
        <div class="flex-column order-pic-item">
          <span class="demonstration">入场</span>
          <el-image
            :src="images.inImg"
            class="img"
            :preview-src-list="[images.inImg]"
          ></el-image>
        </div>
        <div class="flex-column order-pic-item">
          <span class="demonstration">出场</span>
          <el-image
            :src="images.outImg"
            class="img"
            :preview-src-list="[images.outImg]"
          ></el-image>
        </div>
      </div>

      <el-table
        border
        stripe
        :data="dataList1"
        style="width: 90%; margin-left: 5%"
      >
        <el-table-column type="index"></el-table-column>
        <!-- <el-table-column label="车牌号码" prop="carno"></el-table-column> -->
        <el-table-column label="支付金额" prop="payFee"></el-table-column>
        <el-table-column label="支付时间" prop="payTime"></el-table-column>
        <el-table-column label="支付方式">
          <template slot-scope="scope">
            <span v-if="scope.row.payType === '1201'">帐户储值</span>
            <span v-else-if="scope.row.payType === '1202'">支付宝</span>
            <span v-else-if="scope.row.payType === '1203'">信用卡</span>
            <span v-else-if="scope.row.payType === '1204'">银行卡</span>
            <span v-else-if="scope.row.payType === '1205'">微信</span>
            <span v-else-if="scope.row.payType === '1206'">现金</span>
            <span v-else-if="scope.row.payType === '1211'">停车卡</span>
            <span v-else-if="scope.row.payType === '1212'">公众号支付</span>
            <span v-else-if="scope.row.payType === '1229'">ETC支付</span>
            <span v-else-if="scope.row.payType === '1227'">建行聚合</span>
            <span v-else> 其他 </span>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogClose">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { exportFileV2 } from "../../utils/ExportFileDownload";
import { formatParamsDate, licensePlateColors } from "../../utils/utils";
import ProTable from "../procomponents/ProTable.vue";
export default {
  name: "order",
  components: {
    ProTable,
  },
  data() {
    return {
      columns: [
        {
          // hideInSearch: true,
          label: "订单编号",
          name: "orderNo",
          width: 180,
          type: "input",
        },
        {
          width: 110,
          label: "车牌号码",
          name: "plateNo",
          type: "input",
        },
        {
          hideInSearch: true,
          label: "车牌类型",
          name: "plateType",
          type: "select",
          dataSource: licensePlateColors,
        },

        {
          label: "订单状态",
          name: "orderStatus",
          type: "select",
          dataSource: [
            {
              label: "全部",
              value: "",
            },
            {
              label: "场内",
              value: "0",
            },
            {
              label: "自动放行",
              value: "1",
            },
            {
              label: "免费放行",
              value: "4",
            },
            {
              label: "现金放行",
              value: "2",
            },
            {
              label: "软件结束",
              value: "6",
            },
            {
              label: "欠费",
              value: "7",
            },
            {
              label: "欠费驶离",
              value: "77",
            },
            {
              label: "后台放行",
              value: "8",
            },
            {
              label: "人工删除",
              value: "9",
            },
          ],
        },
        {
          label: "驶入时间",
          name: "timeIn",
          width: 150,
          type: "dateRange",
          defaultValue: [
            formatParamsDate(new Date(), -1),
            formatParamsDate(new Date(), 0, "23:59:59"),
          ],
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1],
            };
          },
        },
        {
          label: "驶离时间",
          name: "timeOut",
          width: 150,
          type: "dateRange",
          dataFormater: (value) => {
            return {
              startDate1: value[0],
              endDate1: value[1],
            };
          },
        },
        {
          hideInSearch: true,
          label: "时长",
          name: "parkDuration",
          type: "input",
        },
        {
          hideInSearch: true,
          label: "订单金额",
          name: "orderFee",
          type: "input",
        },
        {
          hideInSearch: true,
          label: "支付金额",
          name: "payFee",
          type: "input",
        },

        {
          hideInSearch: true,
          label: "操作",
          name: "options",
          width: 100,
          fixed: "right",
          slot: "options",
        },
      ],
      imgDialogVisible: false, // 控制图片窗口显示
      baseUrl: "",
      dataList: [],
      dataList1: [],
      images: {
        inImg: "",
        outImg: "",
      },
    };
  },
  mounted() {
    this.baseUrl = this.$store.state.baseUrl; // 请求base地址
    const parkCode = window.localStorage.getItem("LOGIN_PACKCODE");
    if (parkCode == "371109") {
      this.columns.splice(9, 0, {
        hideInSearch: true,
        label: "当日已驶入次数",
        name: "inCounts",
      });
    }
  },
  methods: {
    async request(query) {
      var { data: res } = await this.$http.get(
        this.$store.state.baseUrl + "queryorders",
        {
          params: query,
        }
      );
      res = JSON.parse(res);
      return res;
    },

    // 打开订单图片窗口
    async showImgDialogVisible(inImg, outImg, nid, orderFee) {
      // 设置入场出场图片
      this.images.inImg = inImg;
      this.images.outImg = outImg;
      if (orderFee > 0) {
        var { data: res } = await this.$http.get(
          this.baseUrl + "queryOrderPay",
          {
            params: {
              nid: nid,
              parkCode: window.localStorage.getItem("LOGIN_PACKCODE"),
            },
          }
        );
        res = JSON.parse(res);
        if (res.code === "0") {
          this.dataList1 = res.data;
        }
      }
      this.imgDialogVisible = true;
    },

    // 关闭订单图片窗口
    imgDialogClose() {
      this.images.inImg = "";
      this.images.outImg = "";
      this.dataList1 = [];
      this.imgDialogVisible = false;
    },

    // 导出订单到excel
    exportOrders() {
      const params = this.$refs.table.paramsFilter();
      exportFileV2(this, `${this.$store.state.baseUrl}exportOrderList`, params);
    },
  },
};
</script>

<style lang="less" scoped>
.img {
  width: 90%;
  height: 300px;
}
.order-pic-item {
  width: 50%;
  align-items: center;
  min-width: 300px;
}
.demonstration {
  margin: 5px;
}
.order-pic {
  flex-wrap: wrap;
}
</style>
