<template>
  <div class="pay-container" style="align-items: center;">

    <template v-if="!loading">
      <!-- 车牌号输入 -->
      <div class="number-input max800" v-if="showNoInput">
        <div class="flex flex-row" style="background-color: white;  border-radius: 8px;align-items: center;">
          <input v-model="currentNo" type="text" class="input" placeholder="请输入车牌号" />
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{getCurrentPlateType(currentPlateType)}}<i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in plateTypes" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div style="margin-right: 10px;"></div>
        </div>

        <el-button :loading="checkLoading" type="primary" class="btn" @click="checkFee()">查询</el-button>
        <div class="flex flex-row" style="width: 300px;margin-top: 10px;flex-wrap: wrap;">
          <el-tag
            @click="setDefaultPlate(item)"
            style="margin: 2px;"
            v-for="item in localSearchArray"
            :key="item.plateNo"
            type="info"
            effect="plain">
            {{ item.plateNo }}
          </el-tag>
        </div>
        <div class="clear" style="align-self: flex-start;" @click="clearSearchHistroy()">清除历史</div>
      </div>
      <!-- body -->
      <div class="max800" style="width: 100%;" v-else>
        <div style="height: 20px;"></div>
        <div class="card">
          <div class="flex-row" style="align-items: center;">
            <!-- <img src="@/assets/images/logo.png" alt="" class="logo"> -->
            <div class="name">{{orderInfo.parkInfo.parkName}}</div>
          </div>
          <div class="money">{{orderFee('needPay')}}<div class="sub-money">元</div></div>
          <div class="flex-row" style="align-items: center;">
            <div class="cardNo">{{getComputedOrderInfo('plateNo')}}</div>
            <!-- <div class="change-no" @click="changeNo">修改</div> -->
          </div>
          <div style="height: 10px;"></div>
          <div class="flex-row" style="width: 100%;">
            <div class="label">入场时间： </div>
            <div class="label-value">{{getComputedOrderInfo('timeIn')}}</div>
          </div>
          <div class="flex-row mt-5" style="width: 100%;">
            <div class="label">计费时长： </div>
            <div class="label-value">{{parkDuration(getComputedOrderInfo('parkDuration'))}}</div>
          </div>

          <div style="border-bottom: 1px dashed #f1f1f1;width: 100%;margin: 15px 0;"></div>
          <div class="flex-row" style="width: 100%;">
            <div class="label" style="flex:1">费用类型</div>
            <div class="label money-item">费用金额</div>
          </div>
          <!-- 费用类目 -->
          <div style="max-height: 260px;overflow: auto;width: 100%;">
            <div class="flex-row item" style="width: 100%;">
              <div class="label-value" style="flex:1">原始计费</div>
              <div class="label-value money-item">￥{{orderFee('totalCost')}}</div>
            </div>
            <div class="flex-row item" style="width: 100%;">
              <div class="label-value" style="flex:1">订单减免费用</div>
              <div class="label-value money-item">￥{{orderFee('derateFee')}}</div>
            </div>
            <div class="flex-row item" style="width: 100%;">
              <div class="label-value" style="flex:1">订单金额</div>
              <div class="label-value money-item">￥{{orderFee('realCost')}}</div>
            </div>
            <div class="flex-row item" style="width: 100%;">
              <div class="label-value" style="flex:1">优惠券</div>
              <div class="label-value money-item">￥{{orderFee('couponFee')}}</div>
            </div>
            <div class="flex-row item" style="width: 100%;">
              <div class="label-value" style="flex:1">已支付金额</div>
              <div class="label-value money-item">￥{{orderFee('hasPaid')}}</div>
            </div>
          </div>
          <el-button :loading="payLoading" type="primary" class="btn" @click="pay()">立即支付￥{{orderFee('needPay')}}</el-button>
        </div>
      </div>
    </template>
    <div class="max800" v-else v-loading.fullscreen.lock="loading"></div>
  </div>
</template>
<script>
import { browserType } from '@/utils/utils'
import { unionPay } from '@/utils/PayChannel'
const TOKEN_TAG = 'qr_token'
const SEARCH_PLATE_TAG = 'search_plate_no'
export default {

  data () {
    return {
      loading: true,
      payLoading: false,
      currentNo: '',
      checkLoading: false,
      orderInfo: {},
      showNoInput: false,
      baseUrl: '',
      // 蓝牌：7201，黄牌：7202，黑牌：7203，白绿：7204，黄绿：7205，纯绿：7206，白牌：7207
      plateTypes: [
        {
          label: '蓝牌',
          value: '7201'
        },
        {
          label: '黄牌',
          value: '7202'
        },
        {
          label: '黑牌',
          value: '7203'
        },
        {
          label: '白绿',
          value: '7204'
        },
        {
          label: '黄绿',
          value: '7205'
        },
        {
          label: '纯绿',
          value: '7206'
        },
        {
          label: '白牌',
          value: '7207'
        }
      ],
      currentPlateType: '7201',
      localSearchArray: []
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl.replace('/api/client', '')
    const localOpenId = window.localStorage.getItem(TOKEN_TAG)
    if (localOpenId && localOpenId != null) {
      this.logic()
      return
    }
    const type = browserType()
    if (type === 'wechat') {
      const code = this.$route.query.code
      if (code) {
        this.code2Token(code)
      } else {
        this.login()
      }
    } else if (type === 'alipay') {
      this.logic()
    } else {
      this.toResultPage(400, '警告', '请使用支付宝或者微信扫码支付。')
    }
  },
  computed: {
    getComputedOrderInfo () {
      return function (key) {
        if (this.orderInfo.orderInfo) {
          return this.orderInfo.orderInfo[key]
        }
        return '-'
      }
    },
    orderFee () {
      return function (key) {
        if (this.orderInfo.orderInfo && this.orderInfo.orderInfo.orderFee) {
          const res = this.orderInfo.orderInfo.orderFee[key]
          return res
        }
        return '-'
      }
    },
    getCurrentPlateType () {
      return function (value) {
        return this.plateTypes.find(item => item.value === value).label
      }
    },
    // 将分钟转化为 天时分
    parkDuration () {
      return function (value) {
        if (value === '-') {
          return '-'
        }
        const day = Math.floor(value / 60 / 24)
        const hour = Math.floor(value / 60 % 24)
        const minute = value % 60
        var res = ''
        if (day > 0) {
          res += `${day}天`
        }
        if (hour > 0) {
          res += `${hour}小时`
        }
        if (minute > 0) {
          res += `${minute}分钟`
        }
        return res
      }
    }
  },
  methods: {
    // 微信或者支付宝网页授权获取的code 换取业务token
    // 获取到openid 后 通过url 参数position 判断是不是场内码
    async code2Token (code) {
      const { data } = await this.$http.post(this.baseUrl + 'qrPay/getAuth', { code, ...this.$route.query })
      if (data.code === '0' && data.data) {
        this.loading = false
        // 将token存到本地缓存
        localStorage.setItem(TOKEN_TAG, data.data.openId)
        this.logic()
      } else {
        this.$message.error(data.msg)
      }
    },
    /**
     * 微信支付宝登录
     *
     */
    async login () {
      const { data } = await this.$http.post(this.baseUrl + 'qrPay/getUrl', {
        url: encodeURIComponent(window.location.href)
      })
      if (data.code === '0') {
        window.location.href = decodeURIComponent(data.data.redirectUrl)
      } else {
        this.$message.error(data.message)
      }
    },
    async checkFee () {
      if (this.currentNo.length <= 0) {
        this.$message.error('请输入正确的车牌号码')
        return
      }
      this.checkLoading = true
      // 蓝牌：7201，黄牌：7202，黑牌：7203，白绿：7204，黄绿：7205，纯绿：7206，白牌：7207
      const no = this.currentNo.toLocaleUpperCase()
      const query = { ...this.$route.query, plateNo: no, plateType: this.currentPlateType }
      this.saveSearchedPlateNumber({ plateNo: no, plateType: this.currentPlateType })
      this.getOrderInfo(query, 'qrPay/innerOrderDetail')
    },
    changeNo () {
      this.showNoInput = true
    },
    logic () {
      const query = this.$route.query
      // 判断是不是无牌车入场
      if (query.tempPlate === 'inPark') {
        this.tempPlateEntrance(query)
        return
      }
      if (query.position === 'exitPark') {
        // 出口
        this.getOrderInfo(query, 'qrPay/exitOrderDetail')
      } else if (query.position === 'innerPark') {
        // 场内支付
        this.showNoInput = true
        this.loading = false
        this.localSearchArray = this.getLocalSearchedPlateNumber()
      } else {
        this.toResultPage(400, '警告', 'URL参数错误，请联系客服更换二维码。')
      }
    },
    /**
     * 下单支付
     */
    async pay () {
      this.payLoading = true
      const params = {
        orderNo: this.orderInfo.orderInfo.orderNo,
        orderType: this.orderInfo.orderInfo.orderType || '8202',
        payScheme: this.orderInfo.payInfo.payScheme,
        payType: this.orderInfo.payInfo.payType,
        // 用于扫码展示和支付金额与下单时候金额支付不一样
        needPay: this.orderInfo.orderInfo.orderFee.needPay,
        // buyerId 换成openid
        openId: window.localStorage.getItem(TOKEN_TAG),
        parkCode: this.$route.query.parkCode
      }
      // console.log('pay', params)
      const { data } = await this.$http.post(this.baseUrl + 'qrPay/createJSAPIOrderPay', params)
      if (data.code !== '0') {
        this.payLoading = false
        this.$message.error(data.message)
      }
      // payScheme, payType,
      unionPay(params.payScheme, params.payType, data.data, res => {
        // console.log(res)
        this.toResultPage(res.code, res.msg, '')
      })
    },
    /**
     * 此接口需要获取到openid 进行操作
     * @param {*} query url 查询参数
     * @param {*} type qrPay/innerOrderDetail 场内提前付订单查询接口  qrPay/exitOrderDetail 出口码
     */
    async getOrderInfo (query, type = 'qrPay/innerOrderDetail') {
      const params = { ...query, openId: window.localStorage.getItem(TOKEN_TAG) }
      const { data } = await this.$http.post(this.baseUrl + type, params)
      if (data.code !== '0') {
        this.$message.error(data.message)
        return
      }
      const orderInfo = data.data.orderInfo
      if (!orderInfo) {
        if (type === 'qrPay/exitOrderDetail') {
          // 出口码信息
          this.toResultPage(400, '当前没有订单信息', '当前车牌号没有查到订单信息，请确认车牌号是否正确。')
        } else {
          this.toResultPage(400, '当前没有订单信息', '当前没有车辆。')
        }
        return
      }
      this.checkLoading = false
      this.loading = false
      this.orderInfo = data.data
      this.showNoInput = false
    },
    /**
     * 无牌车入场
     * @param {*} query 浏览器参数
     */
    async tempPlateEntrance (query) {
      const params = { ...query, openId: window.localStorage.getItem(TOKEN_TAG) }
      const { data } = await this.$http.post(this.baseUrl + 'qrPay/tempPlateEntrance', { params })
      // console.log(data)
      let code = 200
      let msg = '您已经入场成功，欢迎光临！'
      if (data.code !== '0') {
        code = 400
        msg = data.message
      }
      this.toResultPage(code, msg)
    },
    handleCommand (e) {
      this.currentPlateType = e
    },
    setDefaultPlate (value) {
      this.currentPlateType = value.plateType
      this.currentNo = value.plateNo
      this.checkFee()
    },
    saveSearchedPlateNumber (search) {
      const array = this.getLocalSearchedPlateNumber()
      // 如果已经存在了就不再添加
      if (array.findIndex(item => item.plateNo === search.plateNo && item.plateType === search.plateType) === -1) {
        array.push(search)
      }
      // 如果 array 超过20 个就删除第一个
      if (array.length > 20) {
        array.shift()
      }
      window.localStorage.setItem(SEARCH_PLATE_TAG, JSON.stringify(array))
    },
    getLocalSearchedPlateNumber () {
      return JSON.parse(window.localStorage.getItem(SEARCH_PLATE_TAG) || JSON.stringify([]))
    },
    clearSearchHistroy () {
      window.localStorage.removeItem(SEARCH_PLATE_TAG)
      this.localSearchArray = []
    },
    /**
     * '请您在15分钟内离开，如果超时，可能需要支付额外费用。\n 感谢您的合作和理解！'
     * @param {*} code 用户图片显示
     * @param {*} msg 底部的信息
     * @param {*} data 中间的信息
     */
    toResultPage (code, data, msg = null) {
      this.$router.push({
        name: 'QrPayResult',
        query: {
          code,
          msg,
          data
        },
        params: {
          query: this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .clear{
    margin-top: 5px;
    font-size: 12px;
    color: #666;
  }
  .btn {
    border-color: #2aae67;
    width: 300px;
    align-self: center;
    margin-top: 20px;
    background-color: #2aae67;
  }
  .btn:hover,
  .btn:focus {
    background: #2aae67;
    border-color: #2aae67;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .pay-container{
    background-color: #f8f8f8;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .name {
    font-size: 16px;
  }
  .cardNo {
    font-weight: bold;
    font-size: 18px;
  }
  .card {
    background: white;
    border-radius: 8px;
    margin: 5px 15px;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 12px 0px rgba(177, 180, 179, 0.2);
  }
  .logo {
    width: 16px;
    height: 16px;
  }
  .money {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: 38px;
    font-weight: bold;
  }
  .sub-money {
    font-size: 12px;
    font-weight: 300;
  }
  .label{
    font-weight: bold;
    font-size: 12px;
  }
  .label-value {
    color: #333;
    font-size: 12px;
   }
   .item {
    height: 30px;
    align-items: center;
    display: flex;
    border-bottom: 0.5px solid #f1f1f1;
   }
   .bottom {
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
   }
  .fix-iphoneX {
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);
    /* 兼容 iOS >= 11.2 */
  }
  .input {
    display: flex;
    border: none;
    outline: none;
    height: 44px;
    font-size: 18px;
    margin-left: 10px;
  }
  .number-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
  }
  .max800 {
    max-width: 800px;
  }
  .change-no{
    font-size: 12px;
    color: #4c82e6;
    text-decoration:underline;
    margin-left: 5px;
  }
  div {
    font-family:sans-serif;
  }
  .money-item {
    text-align: right;
    width: 20%;
  }
</style>
