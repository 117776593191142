
/**
 * webrtc 控制 配合下载webrtc-streamer，并在本地运行。注意请保持本地端口8000空闲。
 */
class WebrtcVideo {
  _serveUrl = ''
  _videoUrl = ''

  _peerConnection = null;

  _earlyCandidates = []

  _peerId = ''

  _callback = {}

  constructor (serveUrl, videoUrl, callback) {
    this._serveUrl = serveUrl
    this._videoUrl = videoUrl

    this._callback = callback
  }

  /**
   * 链接设备
   */
  connect () {
    fetch(`${this._serveUrl}/api/getIceServers`)
      .catch(() => {
        // 链接失败 提醒当前服务未开启
        console.log('链接失败 提醒当前服务未开启')

        this._callback.onError('webrtc-streamer was not running！')
      })
      .then(response => {
        if (response && response.ok) {
          this.createPeerConnection()
        }
      })
  }

  // 获取到服务端的信令
  onReceiveGetIceServers (response) {
    console.log('onReceiveGetIceServers', response)
  }

  /**
   * 创建链接的信息
   */
  createPeerConnection () {
    this._peerId = this.guid()
    /**
     * 内网下不需要 转发服务器不需要配置
     */
    const peerConnection = new RTCPeerConnection()

    const callUrl = `${this._serveUrl}/api/call?peerid=${
      this._peerId
    }&url=${encodeURIComponent(this._videoUrl)}`

    peerConnection.onicecandidate = evt => this.onIceCandidate(evt)

    peerConnection.ontrack = (evt) => {
      // console.log('获取到远端视频', evt)
      // 获取到远端视频
      const remoteStream = evt.streams[0]

      this._callback.onVideo(remoteStream)
    }
    peerConnection.addTransceiver('video')
    peerConnection.oniceconnectionstatechange = () => {
      // console.log(
      //   'oniceconnectionstatechange  state: ',
      //   this._peerConnection?.iceConnectionState
      // )

      const status = this._peerConnection.iceConnectionState || ''
      if (status === 'new') {
        this.getIceCandidate()
      } else if (status === 'connected') {
        this._callback.onConnect()
      } else if (status === 'disconnected') {
        this._callback.onError('video was not connected！')
      }
    }

    this._earlyCandidates = []

    this._peerConnection = peerConnection
    /**
     * 目前不需要音频
     */
    peerConnection
      .createOffer({ offerToReceiveAudio: false, offerToReceiveVideo: true })
      .then(sessionDescription => {
        // 创建offer 成功
        peerConnection.setLocalDescription(sessionDescription).then(() => {
          fetch(callUrl, {
            method: 'POST',
            body: JSON.stringify(sessionDescription)
          })
            .then(this.handleHttpErrors)
            .then(response => response.json())
            .then(response => this.onReceiveCall(response))
        })
      })
  }

  disconnect () {
    if (this._peerId && this._peerId.length > 0) {
      fetch(`${this._serveUrl}/api/hangup?peerid=${this._peerId}`)
        .then(this.handleHttpErrors)
        .catch(() => {})
    }
    this._peerId = ''
    if (this._peerConnection == null) {
      return
    }
    this._peerConnection.close()
  }

  handleHttpErrors (response) {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response
  }

  onReceiveCall (dataJSON) {
    const descr = new RTCSessionDescription(dataJSON)
    if (this._peerConnection == null) {
      return
    }

    this._peerConnection.setRemoteDescription(descr).then(() => {
      while (this._earlyCandidates.length) {
        const candidate = this._earlyCandidates.shift()
        this.addIceCandidate(this._peerId, candidate)
      }
      this.getIceCandidate()
    })
  }

  getIceCandidate () {
    fetch(`${this._serveUrl}/api/getIceCandidate?peerid=${this._peerId}`)
      .then(this.handleHttpErrors)
      .then(response => response.json())
      .then(response => this.onReceiveCandidate(response))
  }

  onReceiveCandidate (dataJSON) {
    if (dataJSON) {
      for (let i = 0; i < dataJSON.length; i++) {
        const candidate = new RTCIceCandidate(dataJSON[i])

        //  console.log('Adding ICE candidate :' + JSON.stringify(candidate));
        if (this._peerConnection != null) {
          this._peerConnection.addIceCandidate(candidate).then(
            () => {
              // console.log('addIceCandidate OK');
            },
            error => {
              console.log('addIceCandidate error:' + JSON.stringify(error))
            }
          )
        }
      }
      if (this._peerConnection != null) {
        this._peerConnection.addIceCandidate()
      }
    }
  }

  onIceCandidate (event) {
    if (event.candidate) {
      if (this._peerConnection.currentRemoteDescription) {
        this.addIceCandidate(this._peerId, event.candidate)
      } else {
        this._earlyCandidates.push(event.candidate)
      }
    }
  }

  addIceCandidate (peerId, candidate) {
    fetch(`${this._serveUrl}/api/addIceCandidate?peerid=${peerId}`, {
      method: 'POST',
      body: JSON.stringify(candidate)
    })
      .then(this.handleHttpErrors)
      .then(response => response.json())
      .then(response => {
        console.log('addIceCandidate ok:' + response)
      })
  }

  guid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line one-var
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
}

export default WebrtcVideo
