<template>
  <div>
    <video webkit-playsinline="true"  x-webkit-airplay="allow" playsinline ref="video" style='width: 100%; height: 400px;' muted controls v-if="videoStatus == 1"></video>
    <EmptyShow :videoStatus="videoStatus" @reconnect="reconnect" msg="请确保内网，并且webrtc-streamer在80端口运行！"/>
  </div>
</template>

<script>
import WebrtcVideo from './WebrtcVideo'
import EmptyShow from '../EmptyShow/index.vue'
import { getDefaultLocalSetting } from '../../../utils/utils'

export default {
  components: {
    EmptyShow
  },
  props: {
    rtspURL: String
  },
  data () {
    return {
      videoStatus: 1
    }
  },
  methods: {
    reconnect () {
      console.log('reconnect')
      this.videoStatus = 1
      const that = this
      this.reconnectTimeOut = setTimeout(() => {
        const webrtcVideo = that.connectVideo(this.rtspURL)
        that.webrtcVideo = webrtcVideo
      }, 1000)
    },
    connectVideo (rtsp) {
      const video = this.$refs.video
      this.videoStatus = 1
      const that = this
      const webRTCServer = getDefaultLocalSetting().webRTCServer
      const webrtcVideo = new WebrtcVideo(webRTCServer, rtsp, {
        onConnect () {
          that.videoStatus = 1
          video.playsinline = true
          video.play()
        },
        onError (err) {
          console.log('onError', err)
          that.videoStatus = -1
        },
        onVideo (remoteVideo) {
          // console.log('onVideo', remoteVideo)
          video.srcObject = remoteVideo
        }
      })
      webrtcVideo.connect()

      return webrtcVideo
    }
  },
  watch: {

    rtspURL (newValue, oldValue) {
      // console.log('rtspURL', newValue, oldValue)
      if (this.webrtcVideo) {
        this.webrtcVideo.disconnect()
      }
      this.connectVideo(newValue)
    }

  },

  mounted () {
    // console.log('mounted', this.rtspURL)
    const webrtcVideo = this.connectVideo(this.rtspURL)
    this.webrtcVideo = webrtcVideo
  },
  beforeDestroy () {
    if (this.webrtcVideo) {
      this.webrtcVideo.disconnect()
    }
    clearTimeout(this.reconnectTimeOut)
  }

}
</script>
