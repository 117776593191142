<template>
  <div class="flex-column">
    <div :style="`width: ${config.previewSize}px;height: ${config.previewSize}px;position: relative;overflow: auto;`" class="flex-column preview">
      <div id="pro-qrcode" style="position: absolute;left: 0;top: 0;display: none;"></div>
      <!--  :width="canvasSize" :height="canvasSize" -->
      <canvas :width="canvasWidth" :height="canvasHeight" style="position: absolute;left: 0;z-index: 9999;top: 0;" id="pro-canvas" ref="canvas"></canvas>
    </div>

    <div style="height: 10px;"></div>
    <div class="flex-row" style="align-items: center;">
      <div style="margin-right: 10px;font-weight: bold;font-size: 18px;color: black;">二维码高级配置</div>
      <el-switch v-model="advanced"></el-switch>
    </div>
    <template v-if="advanced">
      <div style="margin-bottom: 10px;">{{ qrUrl }}</div>
      <div>保存二维码实际大小：{{ config.qrSize }}px <el-slider v-model="config.qrSize" show-tooltip show-stops :min="100"  :max="2000" :step="100"></el-slider></div>
      <div>预览大小：{{ config.previewSize }}px <el-slider v-model="config.previewSize" :step="100" show-stops :min="500"  :max="1000"></el-slider></div>
      <div>底部文字大小：{{ config.qrTextSize }}px <el-slider v-model="config.qrTextSize" :step="2" show-stops :min="0"  :max="96"></el-slider></div>
      <div style="margin-bottom: 10px;">展示中间LOGO：</div>
      <el-switch v-model="config.isLogo"></el-switch>
      <template v-if="config.isLogo">
        <div style="margin-top: 10px;">自定义LOGO图标</div>
        <el-switch v-model="config.customImg"></el-switch>
        <input
          v-if="config.customImg"
          class="avatar-uploader"
          type="file"
          @change="onLogoUpload"/>
        <div style="margin-top: 10px;">中间LOGO圆角大小：{{ config.logoRadius }}px <el-slider v-model="config.logoRadius" :step="2" show-stops :min="0"  :max="200"></el-slider></div>
        <div class="flex-row" style="align-items: center;">中间LOGO背景颜色：<el-color-picker color-format="hex" v-model="config.logoColor" show-alpha></el-color-picker></div>
      </template>
    </template>
    <slot name="action"></slot>
    <el-button style="margin-top: 30px;margin-bottom: 20px;" type="primary" @click="downloadImg()">保存到本地</el-button>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  name: 'ProQRCode',
  props: {
    qrUrl: {
      type: String,
      default: '欢迎光临！'
    },
    qrText: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      canvasWidth: 0,
      canvasHeight: 0,
      advanced: false,
      config: {
        previewSize: 600,
        qrSize: 1000,
        qrTextSize: 18,
        isLogo: true,
        logoRadius: 36,
        customImg: false,
        logoColor: 'rgba(255, 255, 255, 1)'
      }
    }
  },
  mounted () {
    this.drawQR()
  },
  computed: {
    configChanage () {
      // const { config.qrSize, config.qrTextSize } = this
      return {
        qrSize: this.config.qrSize,
        qrTextSize: this.config.qrTextSize,
        isLogo: this.config.isLogo,
        qrUrl: this.qrUrl,
        qrText: this.qrText,
        logoRadius: this.config.logoRadius,
        logoColor: this.config.logoColor,
        customImg: this.config.customImg
      }
    }
  },
  watch: {
    configChanage (newValue, oldValue) {
      this.drawQR()
    }
  },
  methods: {
    drawQR () {
      // console.log('drawQR')
      const qrcode = new QRCode('pro-qrcode', {
        text: this.qrUrl,
        width: this.config.qrSize,
        height: this.config.qrSize,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      // 二维码的图片
      const img = qrcode._oDrawing._elImage
      const canvas = document.getElementById('pro-canvas')
      const ctx = canvas.getContext('2d')

      // 设置画布的实际大小
      this.canvasWidth = this.config.qrSize
      this.canvasHeight = this.config.qrSize + this.config.qrTextSize + 10

      const logoPromise = new Promise((resolve) => {
        const logoImage = new Image()
        logoImage.onload = () => {
          resolve(logoImage)
        }
        var customImg = this.base64Img
        if (!this.config.customImg) {
          customImg = null
        }
        logoImage.src = customImg || require('@/assets/images/pb_logo.png')
      })
      const qrImage = new Promise((resolve) => {
        img.onload = () => {
          resolve(img)
        }
      })
      Promise.all([logoPromise, qrImage]).then(res => {
        // 预留底部文字的高度
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
        // 画二维码
        ctx.drawImage(res[1], 0, 0, this.config.qrSize, this.config.qrSize)
        if (this.config.isLogo) {
          // 此处画logo
          ctx.fillStyle = this.config.logoColor
          const logoSize = this.config.qrSize / 3.7
          const logoX = (this.config.qrSize - logoSize) / 2
          const logoY = (this.config.qrSize - logoSize) / 2
          ctx.beginPath()
          ctx.roundRect(logoX, logoY, logoSize, logoSize, this.config.logoRadius)
          ctx.fill()
          ctx.drawImage(res[0], logoX, logoY, logoSize, logoSize)
        }
        // 文本底部的的字体
        if (this.qrText && this.config.qrTextSize > 0) {
          // 设置字体
          ctx.font = `bold  ${this.config.qrTextSize}px Arial`
          const tw = ctx.measureText(this.qrText).width // 文字真实宽度
          const fleft = (this.config.qrSize - tw) / 2
          ctx.fillStyle = 'black'
          ctx.textBaseline = 'top'
          ctx.fillText(this.qrText, fleft, this.config.qrSize + 10)
        }
      })
    },
    downloadImg () {
      const canvas = document.getElementById('pro-canvas')
      var link = document.createElement('a')
      var imgData = canvas.toDataURL({ format: 'png', quality: 1, width: this.config.qrSize, height: this.config.qrSize })
      var blob = this.dataURLtoBlob(imgData)
      var objurl = URL.createObjectURL(blob)
      link.download = 'temp.png'
      link.href = objurl
      link.click()
    },
    dataURLtoBlob (dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    onLogoUpload (e) {
      const files = e.target.files
      if (files.length > 0) {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          // e.target.result base64img
          this.base64Img = e.target.result
          this.drawQR()
        }
        reader.readAsDataURL(file)
      }
    }
  }
}
</script>

<style scoped>
.preview{
  border:  1px solid #999;
  border-radius: 6px;
}

.avatar-uploader {
  margin-top: 10px;
}
</style>
