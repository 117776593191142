<template>
  <div style="height: 100vh;overflow: auto;">
    <el-alert
      style="width: 90%;margin: 20px auto;"
      title="请在内网打开这个页面查看所有摄像头"
      type="error"
      effect="dark">
    </el-alert>
    <el-row style="width: 100%;">
      <el-col v-for="(item, index) in list" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        <el-card  style="margin:10px">
          <div slot="header" class="clearfix">
            <el-popover
              placement="top-start"
              :title="item.devicename"
              width="300"
              trigger="hover">
              <div class="flex-column">
                <span class="spanx">设备号码：{{ item.devicecode }}</span>
                <span class="spanx">设备IP：{{ item.ip }}</span>
                <span class="spanx">voiceChannel：{{ item.voiceChannel }}</span>
                <span class="spanx">pointcode：{{ item.pointcode }}</span>
                <div style="margin-top: 10px;">
                  <el-tag size="mini" type="success" :closable="false" v-if="item.videofunc == '9'">入口</el-tag>
                  <el-tag size="mini" :closable="false" v-if="item.videofunc == '10'">出口</el-tag>
                </div>
              </div>
              <el-button slot="reference" size="mini">{{ item.devicename }}</el-button>
            </el-popover>
          </div>
          <VideoPlayer :rtspURL="item.rtspAddress" :wsURL="'ws://' + item.ip + ':9080/ws'"/>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import VideoPlayer from './video/VideoPlayer'
export default {
  components: {
    VideoPlayer
  },
  data () {
    return {
      list: []
    }
  },
  mounted () {
    const list = this.$store.state.parkConfig.equipList
    console.log(list)
    this.list = list
  }

}
</script>

<style lang="less" scoped>
.spanx{
  font-size: 12px;
}
/deep/ .el-card__header {
    padding: 10px !important;
  }

</style>
