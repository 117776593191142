import Axios from 'axios'

export const exportFile = async (that, exportUrl, fileName = '下载的文件.xls') => {
  const loading = that.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  try {
    const res = await Axios.get(exportUrl, {
      timeout: 10 * 1000,
      responseType: 'blob'
    })
    const blob = new Blob([res.data], { type: 'application/octet-stream' })
    const url = window.URL.createObjectURL(blob)
    // 创建临时的a链接添加点击事件用于下载以下为a标签方法
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    // 释放url
    window.URL.revokeObjectURL(url)
    that.$message.success('正在下载')
    loading.close()
  } catch (e) {
    loading.close()
    that.$message.error(e.message)
  }
}
export const exportFileV2 = async (that, exportUrl, params, fileName = '下载的文件.xls') => {
  const loading = that.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  try {
    const res = await Axios.get(exportUrl, {
      params,
      timeout: 10 * 1000,
      responseType: 'blob'
    })
    const blob = new Blob([res.data], { type: 'application/octet-stream' })
    const url = window.URL.createObjectURL(blob)
    // 创建临时的a链接添加点击事件用于下载以下为a标签方法
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    // 释放url
    window.URL.revokeObjectURL(url)
    that.$message.success('正在下载')
    loading.close()
  } catch (e) {
    loading.close()
    that.$message.error(e.message)
  }
}
