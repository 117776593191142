<template>
  <div class="container">
    <div v-if="!active" style="width: 100%;margin-top: 100px;" class="flex-column v-center">
      <el-card shadow="never" style="width: 600px;">
        <div class="title">需要激活</div>
        <div class="sub-title">激活后，体验全部功能。</div>
        <div style="margin-top: 20px;" class="flex-column">
          <ActivationInput @onChange="onChange" />
          <div style="align-self: flex-end;">
            <!--  -->
            <el-button type="primary" style="margin-top: 20px;" :disabled="inputValue.length < 30"
              @click="goActive">立即激活</el-button>
          </div>
        </div>
      </el-card>

    </div>

    <slot name="components" v-else></slot>
  </div>
</template>

<script>
import ActivationInput from './ActivationInput.vue'

export default {
  components: {
    ActivationInput
  },
  computed: {
    active () {
      return this.$store.state.parkConfig.active
    }
  },
  data: () => {
    return {
      inputValue: ''
    }
  },
  methods: {
    onChange (e) {
      this.inputValue = e
    },
    async goActive () {
      const baseUrl = this.$store.state.baseUrl.replace('/api/client', '')

      try {
        var { data: res } = await this.$http.get(baseUrl + 'checkAdData')
        if (res.code === '0') {
          this.$message.success('激活成功')
          this.$store.commit('updateActive', true)
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {}
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
}

.title {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 22px;
}

.sub-title {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}</style>
