export const unionPay = (payScheme, payType, data, callback = (_) => {}) => {
  switch (payScheme) {
    case '0602':
      // 微信支付
      wxPay(data, callback)
      break
    case '0603':
      // 支付宝支付
      aliPay(data, callback)
      break
    case '0604':
      // swiftPay
      if (payType === '0804') {
        wxPay(data, callback)
      } else if (payType === '0805') {
        aliPay(data.tradeNO, callback)
      } else {
        urlPay(data, callback)
      }
      break
    case '0605':
      // chinaUmsPay
      urlPay(data.url, callback)
      break
    case '0611':
      // ccbPay
      urlPay(data, callback)
      break
    case '0612':
      // unionPay
      if (payType === '0804') {
        wxPay(data, callback)
      } else if (payType === '0805') {
        urlPay(data, callback)
      } else {
        callback(Msg(500, '不支持的支付方式'))
      }
      break
    default :
      callback(Msg(500, '不支持的支付方式'))
      break
  }
}

export const wxPay = (data, callback = (_) => {}) => {
  window.WeixinJSBridge.invoke('getBrandWCPayRequest', data, res => {
    console.log(res)
    if (res.err_msg === 'get_brand_wcpay_request:ok') {
      // 支付成功
      callback(Msg(200, '支付成功'))
    } else {
      // 支付失败
      callback(Msg(400, '支付失败'))
    }
  })
}
export const aliPay = (data, callback = (_) => {}) => {
  window.AlipayJSBridge.call('tradePay', data, res => {
    if (res.resultCode === '9000') {
      // 支付成功
      callback(Msg(200, '支付成功'))
    } else {
      // 支付失败
      callback(Msg(400, '支付失败'))
    }
  })
}
export const urlPay = (data, callback = (_) => {}) => {
  // console.log('urlPay', data)
  window.location.href = data
}

export const Msg = (code, msg) => {
  return {
    code,
    msg
  }
}
