<template>
  <div class="container-aaa">
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
        <el-button type="primary"  @click="showxqDialogVisible"  size="small">清&nbsp;&nbsp;空</el-button>
      </template>
      <template v-slot:options="data">
        <el-button type="primary" icon="el-icon-s-order" size="mini" @click="queryDetailList(data.scope.row.id)">详情</el-button>
        <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteAbnormal(data.scope.row.id)">删除</el-button>
      </template>
    </ProTable>
    <el-dialog title="异常车辆清空" :visible.sync="xqDialogVisible" width="50%" @close="xqDialogClose">
      <el-form :model="xqForm" ref="xqFormRef" label-width="150px">
        <el-form-item label="异常次数及以下" prop="start">
          <el-input v-model="xqForm.num" type="number" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="xqDialogClose">取 消</el-button>
        <el-button type="primary" @click="clearAbnormal">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="异常车辆详情" :visible.sync="deDialogVisible" width="80%" @close="deDialogClose">
      <el-table border stripe :data="dataList1" style="width: 90%;margin-left: 5%">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="车牌号码" prop="plateNo"></el-table-column>
        <el-table-column label="车牌颜色">
          <template slot-scope="scope">
            <ProTag :data="getSelectLabel(scope.row.plateType)"/>
          </template>
        </el-table-column>
        <el-table-column label="驶离时间" prop="outTimeStr"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-picture" size="mini" @click="showImgDialogVisible(scope.row.imgUrl)">图片</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deDialogClose">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="识别图片" :visible.sync="imgDialogVisible" width="40%" @close="imgDialogClose">
      <div class="demo-image__placeholder">
        <div class="block">
            <el-image :src=images.pic ></el-image>
          </div>
        </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogClose">关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import ProTable from '../procomponents/ProTable.vue'
import ProTag from '../procomponents/ProTag.vue'
import { licensePlateColors, licensePlateValueByType } from '@/utils/utils'
export default {
  name: 'abnormal',
  components: {
    ProTable,
    ProTag
  },
  data () {
    return {
      columns: [
        {
          label: '车牌号码',
          name: 'carno',
          type: 'input',
          dataFormater: (value) => {
            return {
              plateNo: value
            }
          }
        },
        {
          label: '异常订单次数',
          name: 'cumulNum',
          type: 'input',
          dataFormater: (value) => {
            return {
              num: value
            }
          }
        },
        {
          hideInSearch: true,
          label: '车牌颜色',
          name: 'plateType',
          type: 'select',
          dataSource: licensePlateColors
        },
        {
          fixed: 'right',
          width: 250,
          hideInSearch: true,
          label: '操作',
          name: 'options',
          slot: 'options'
        }
      ],
      imgDialogVisible: false, // 控制图片窗口显示
      deDialogVisible: false, // 详情窗口是否显示
      xqDialogVisible: false, // 清空窗口是否显示
      dataList: [],
      dataList1: [],
      xqForm: {
        num: 1
      },
      images: {
        pic: ''
      }
    }
  },
  computed: {
    getSelectLabel () {
      return function (type) {
        return licensePlateValueByType(type)
      }
    }
  },
  mounted () {
    this.queryList()
  },
  methods: {
    async request (query) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryAbnormalkList', {
        params: query
      })
      res = JSON.parse(res)
      return res
    },
    // 获取异常列表
    async queryList () {
      this.$refs.table.queryData()
    },

    // 清空窗口是否显示
    showxqDialogVisible () {
      this.xqDialogVisible = true
    },
    xqDialogClose () {
      this.$refs.xqFormRef.resetFields()
      this.xqDialogVisible = false
    },

    // 删除异常信息
    async deleteAbnormal (id) {
      this.$confirm('是否删除异常订单车辆？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'deleteAbnormal', {
          params: { id: id, parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        // console.log(res)
        if (res.code === '0') {
          this.queryList()
          return this.$message.success(res.message)
        } else {
          return this.$message.error(res.message)
        }
      }).catch(() => {

      })
    },

    // 清空异常车辆
    clearAbnormal () {
      if (this.xqForm.num === '' || this.xqForm.num === null) {
        this.$message.error('请输入有效天数')
        return false
      }

      this.$confirm('确定清空异常订单车辆？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.xqForm.parkCode = window.localStorage.getItem('LOGIN_PACKCODE')
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'clearAbnormal', {
          params: this.xqForm
        })
        res = JSON.parse(res)
        // console.log(res)
        if (res.code === '0') {
          this.queryList()
          this.xqDialogClose()
          return this.$message.success(res.message)
        } else {
          return this.$message.error(res.message)
        }
      }).catch(() => {

      })
    },

    // 详情窗口操作
    showdeDialogVisible () {
      this.deDialogVisible = true
    },
    deDialogClose () {
      this.dataList1 = []
      this.deDialogVisible = false
    },

    // 获取异常订单车辆详情列表
    async queryDetailList (aid) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryDetailList', {
        params: { aid: aid, parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
      })
      res = JSON.parse(res)
      // console.log(res)
      if (res.code === '0') {
      // 查询成功，设置数据
        this.dataList1 = res.data
        this.showdeDialogVisible()
      } else {
        return this.$message.error(res.message)
      }
    },

    // 打开订单图片窗口
    showImgDialogVisible (pic) {
      // 设置入场出场图片
      this.images.pic = pic
      this.imgDialogVisible = true
    },

    // 关闭订单图片窗口
    imgDialogClose () {
      this.images.pic = ''
      this.imgDialogVisible = false
    }

  }

}
</script>

<style lang="less" scoped>
  .container-aaa{
    background-color:#f7f7f7;
    position: relative;
  }
  .el-col {
    margin: 7px 7px 7px 7px;
  }
</style>
