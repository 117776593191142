<template>
  <div style="margin: 10px;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="图片流" name="photo"></el-tab-pane>
      <el-tab-pane label="视频流" name="video"></el-tab-pane>
    </el-tabs>
    <template v-if="activeName === 'video'">
      <VideoPlayer :rtspURL="equip.rtspAddress" :wsURL="'ws://' + equip.ip + ':9080/ws'"/>
    </template>
    <template v-if="activeName === 'photo'">
      <PhotoPlayer :picData="picData" :equip="equip"/>
    </template>
  </div>
</template>

<script>
import VideoPlayer from '../VideoPlayer'
import PhotoPlayer from '../PhotoPlayer/index.vue'
import { getDefaultLocalSetting } from '../../../utils/utils'
export default {
  props: {
    equip: Object,
    picData: Object
  },
  components: {
    VideoPlayer,
    PhotoPlayer
  },
  data () {
    return {
      activeName: 'photo'
    }
  },
  mounted () {
    const video = getDefaultLocalSetting().video
    if (video === '1') {
      this.activeName = 'video'
    }
  }
}
</script>

<style>

</style>
