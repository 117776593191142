<template>
  <div>
    <RTSPVideoPlayer :rtspURL="rtspURL" v-if="rtspURL"/>
    <!-- // 192.168.1.124 ws://192.168.1.120:9080/ws -->
    <WebSocketPlayer :url="wsURL" v-else/>
  </div>
</template>

<script>
import WebSocketPlayer from '../WebSocketPlayer/index.vue'
import RTSPVideoPlayer from '../RTSPVideoPlayer/index.vue'
export default {
  components: {
    WebSocketPlayer,
    RTSPVideoPlayer
  },
  props: ['rtspURL', 'wsURL']

}
</script>

<style>

</style>
