<template>
  <div>
    <ICountUp :style="{color: color, fontSize: fontSize +'px'}" class="num" :delay="delay" :endVal="num" :options="options"></ICountUp>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2'
export default {
  components: {
    ICountUp
  },
  props: {
    num: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#0a55e0'
    },
    fontSize: {
      type: Number,
      default: 38
    }
  },
  data () {
    return {
      delay: 1000,
      endVal: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    }
  }

}
</script>

<style scoped>
 .num {
    font-weight: bold;
    margin-right: 5px;
  }

</style>
