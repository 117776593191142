<template>
  <div class="flex-column">
    <div v-if="videoStatus == -1" class="video" style="display: flex;align-items: center;justify-content: center;">
        <div class="flex-column">
          <img src="@/assets/images/video.jpg" style="height: 200px;width: 100%;"/>
          <el-alert
            style="margin-top: 10px;"
            v-if="msg"
            :title="msg"
            type="error"
            :closable="false"
            show-icon>
          </el-alert>
          <div class="flex-row" style="justify-content: center;margin-top: 10px;">
            <el-link type="primary" @click="reconnect()">点击重新连接视频</el-link>
          </div>
        </div>
      </div>
      <div v-if="videoStatus == 0" class="video" style="display: flex;align-items: center;justify-content: center;">
        <div>
          <el-alert
            type="success"
            :closable="false">
            <template slot='title'>
                <div>视频加载中 <li class="el-icon-loading"></li></div>
            </template>
          </el-alert>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  props: {
    videoStatus: Number,
    msg: String
  },
  methods: {
    reconnect () {
      // console.log('====')
      this.$emit('reconnect', {})
    }
  }
}
</script>

<style>

</style>
