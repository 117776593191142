<template>
  <div>
    <ProTable :columns="columns" :request="request" ref="table">
      <template v-slot:actions>
          <el-button type="success" @click="exportList" size="small">导&nbsp;&nbsp;出</el-button>
      </template>
      <template v-slot:userid="data">
        <el-tag v-if="data.scope.row.username" effect="dark" type="success">{{ data.scope.row.username }}</el-tag>
      </template>
    </ProTable>
  </div>
</template>
<script>
import { exportFileV2 } from '../../utils/ExportFileDownload'
import { formatParamsDate } from '../../utils/utils'
import ProTable from '../procomponents/ProTable.vue'
export default {
  name: 'orderpay',
  components: {
    ProTable
  },
  data () {
    return {
      columns: [
        {
          label: '车牌号码',
          name: 'carno',
          type: 'input',
          dataFormater: (value) => {
            return {
              plateNo: value
            }
          }
        },
        {
          hideInSearch: true,
          label: '驶入时间',
          name: 'collectiondate1',
          type: 'dateRange'
        },
        {
          hideInSearch: true,
          label: '驶出时间',
          name: 'collectiondate2',
          type: 'dateRange'
        },
        {
          label: '支付时间',
          name: 'payTime',
          type: 'dateRange',
          defaultValue: [
            formatParamsDate(new Date(), -1),
            formatParamsDate(new Date(), 0, '23:59:59')
          ],
          dataFormater: (value) => {
            return {
              startDate: value[0],
              endDate: value[1]
            }
          }
        },
        {
          hideInSearch: true,
          label: '支付金额',
          name: 'payFee',
          type: 'input'
        },
        {
          hideInSearch: true,
          label: '停车时长',
          name: 'duration',
          type: 'input'
        },
        {
          label: '支付方式',
          name: 'payType',
          type: 'select',
          dataSource: [
            {
              label: '帐户储值',
              value: '1201'
            },
            {
              label: '支付宝',
              value: '1202'
            },
            {
              label: '信用卡',
              value: '1203'
            },
            {
              label: '银行卡',
              value: '1204'
            },
            {
              label: '微信',
              value: '1205'
            },
            {
              label: '现金',
              value: '1206'
            },
            {
              label: '停车卡',
              value: '1211'
            },
            {
              label: '公众号支付',
              value: '1212'
            },
            {
              label: 'ETC支付',
              value: '1229'
            },
            {
              label: '建行聚合',
              value: '1227'
            }
          ]
        },
        {
          label: '处理人',
          name: 'userid',
          type: 'select',
          slot: 'userid',
          dataSource: []
        }
      ],
      imgDialogVisible: false, // 控制图片窗口显示
      baseUrl: ''
    }
  },
  created () {
    this.baseUrl = this.$store.state.baseUrl// 请求base地址
    this.queryUserList()
  },
  methods: {
    async queryUserList () {
      try {
        var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryUserList', {
          params: { parkCode: window.localStorage.getItem('LOGIN_PACKCODE') }
        })
        res = JSON.parse(res)
        const tmp = [{ userid: '', username: '全部' }]
        const userList = tmp.concat(res.data)

        const list = []
        for (let i = 0; i < userList.length; i++) {
          const temp = {
            label: userList[i].username,
            value: userList[i].userid
          }
          list.push(temp)
        }
        this.columns[7].dataSource = list
      } catch (e) {}
    },
    async request (query) {
      var { data: res } = await this.$http.get(this.$store.state.baseUrl + 'queryPayList', {
        params: query
      })
      res = JSON.parse(res)
      return res
    },
    // 导出支付列表
    exportList () {
      const params = this.$refs.table.paramsFilter()
      exportFileV2(this, `${this.$store.state.baseUrl}exportOrderPayList`, params)
    }

  }
}
</script>

<style lang="less" scoped>
  .demo-image__placeholder{
    .block{
      padding:30px 0;
      text-align:center;
      border-right:1px solid #eff2f6;
      display:inline-block;
      box-sizing:border-box;
      vertical-align:top;
      width:100%;
      &:last-child{
        border-right:none
      }
    }
    .el-image{
      width:450px;
      height:300px
    }
    .demonstration{
      display:block;
      color:#8492a6;
      font-size:14px;
      margin-bottom:20px
    }

  }

</style>
