<template>
  <div style="background-color: white">
    <el-form
      v-if="!hideSearch"
      ref="form"
      :model="form"
      label-width="100px"
      class="flex-row search-wrapper"
      size="mini"
    >
      <el-row :gutter="10" style="width: 100%">
        <el-col
          v-for="(item, index) in columns"
          :key="index"
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          :xl="7"
        >
          <el-form-item
            :label="item.label"
            v-if="!item.hideInSearch"
            :prop="item.name"
          >
            <el-input
              clearable
              v-model="form[`${item.name}`]"
              v-if="item.type === 'input'"
              placeholder="请输入"
            ></el-input>
            <el-date-picker
              style="width: 100%"
              v-model="form[`${item.name}`]"
              v-else-if="item.type === 'dateRange'"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:SS"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-select
              style="width: 100%"
              placeholder="请选择"
              v-model="form[`${item.name}`]"
              v-else-if="item.type === 'select'"
              clearable
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in item.dataSource"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="7">
          <el-form-item label="操作">
            <el-button type="primary" @click="getData" :loading="searchLoading"
              >查询</el-button
            >
            <el-button @click="resetData" :loading="searchLoading"
              >重置</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- table -->
    <el-card>
      <div slot="header">
        <div class="flex-row" style="flex-direction: row-reverse">
          <slot name="actions"></slot>
        </div>
      </div>
      <el-table
        :data="data"
        v-loading="searchLoading"
        size="small"
        @selection-change="selectionChange"
      >
        <el-table-column type="index" label="序列" ref="table"></el-table-column>
        <template v-for="(item, index) in columns">
          <el-table-column
            :fixed="item.fixed"
            :width="item.width"
            :label="item.label"
            :key="index"
            :prop="item.name"
            v-if="!item.hideInTable && 'selection' != item.colType"
          >
            <template slot-scope="scope">
              <slot :name="item.slot" :scope="scope" v-if="item.slot"></slot>

              <ProTag
                :data="getSelectLabel(scope.row[item.name], item.name, columns)"
                v-else-if="item.type === 'select'"
              />
              <div v-else>{{ scope.row[item.name] || "-" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            v-else-if="!item.hideInTable && 'selection' == item.colType"
            :key="index"
            type="selection"
            :width="item.width"
            align="center"
            header-align="center"
          />
        </template>
      </el-table>
    </el-card>
    <!-- 分页 -->
    <el-pagination
      background
      small
      hide-on-single-page
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagerData.pagenum"
      :page-sizes="pageSize"
      :page-size="pagerData.pagesize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    ></el-pagination>
    <div style="height: 100px"></div>
  </div>
</template>

<script>
import ProTag from './ProTag.vue'
export default {
  components: {
    ProTag
  },
  props: ['columns', 'request', 'hideSearch'],
  data () {
    return {
      searchLoading: false,
      form: {},
      pageSize: [10, 20, 50, 100],
      pagerData: {
        pagenum: 1,
        pagesize: 20
      },
      data: [],
      total: 0,
      selectedRows: []
    }
  },
  mounted () {
    const obj = {}
    for (let i = 0; i < this.columns.length; i++) {
      const item = this.columns[i]
      if (item.defaultValue) {
        obj[item.name] = item.defaultValue
      }
    }
    this.form = obj
    this.queryData()
  },
  computed: {
    getSelectLabel () {
      return function (value, name, columns) {
        let list = []
        for (let i = 0; i < columns.length; i++) {
          const column = columns[i]
          if (column.name === name) {
            list = column.dataSource
            break
          }
        }

        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          if (item.value === value) {
            return item
          }
        }
        return null
      }
    }
  },
  methods: {
    getData () {
      this.pagerData.pagenum = 1
      this.queryData()
    },
    resetData () {
      this.form = {}
      this.pagerData.pagenum = 1
      this.queryData()
    },
    handleSizeChange (newSize) {
      this.pagerData.pagesize = newSize
      this.pagerData.pagenum = 1
      this.queryData()
    },
    handleCurrentChange (newPage) {
      this.pagerData.pagenum = newPage
      this.queryData()
    },
    getSelection () {
      return this.selectedRows
    },
    getCurrentData () {
      return Object.assign([], this.data)
    },
    async queryData () {
      const queryData = {
        ...this.pagerData,
        ...this.paramsFilter(),
        parkCode: window.localStorage.getItem('LOGIN_PACKCODE')
      }
      if (this.request) {
        this.searchLoading = true
        try {
          const res = await this.request(queryData)
          this.data = res.data
          this.total = res.total
          // console.log(res.total)
          // this.pageSize = [10, 20, 50, 100, res.total]
        } catch (e) {}
        this.searchLoading = false
      }
    },
    paramsFilter () {
      let params = {}
      for (let i = 0; i < this.columns.length; i++) {
        const item = this.columns[i]
        if (!item.hideInSearch) {
          // 在搜索框里
          const value = this.form[item.name]
          if (value) {
            if (item.dataFormater) {
              params = { ...params, ...item.dataFormater(value) }
            } else {
              params[item.name] = value
            }
          }
        }
      }
      params.parkCode = window.localStorage.getItem('LOGIN_PACKCODE')
      return params
    },
    selectionChange (selection) {
      this.selectedRows = selection
    }
  }
}
</script>

<style scoped>
.search-wrapper {
  flex-wrap: wrap;
  background: #f8f8f8;
  padding-top: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
}

/deep/ .el-card__header {
  padding: 10px !important;
}
</style>
